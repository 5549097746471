<div id="addTeammateDialog">
  <form autocomplete="off" [formGroup]="teamMateForm" (ngSubmit)="inviteTeamMate(teamMateForm.value)" novalidate>
    <div mat-dialog-title>
      <div class="t-20-500-p flex-child-auto">{{translations.team.invite_teammate | translate}}</div>
      <mat-icon class="mat-icon-close cursor-pointer" (click)="close()" fontIcon="close"></mat-icon>
    </div>

    <mat-dialog-content class="cell">
      <mat-form-field appearance="fill" class="width-100 normal-fill border-input no-borders" color="secondary" hideRequiredMarker>
        <mat-label>{{translations.profile.email_address | translate}}</mat-label>
        <input matInput formControlName="email" id="emailInput" name="emailInput" type="email" maxlength="255">
        @if (teamMateForm.controls['email'].hasError('required')) {
          <mat-error>{{translations.profile.email_required | translate}}</mat-error>
        }
        @if (teamMateForm.controls['email'].hasError('pattern')) {
          <mat-error>{{translations.profile.email_not_valid | translate}}</mat-error>
        }
        @if (teamMateForm.controls['email'].hasError('userEmailTaken')) {
          <mat-error>{{translations.profile.email_already_exists | translate}}</mat-error>
        }
      </mat-form-field>

      <div class="m-t-3 m-b-2">
        <span class="t-16-600-s">{{translations.team.invite_as | translate}}</span>
      </div>

      <mat-radio-group formControlName="role" name="invite" id="invite">
        @if (!(userQuery.isUser$ |  async)) {
          <mat-radio-button id="adminRadioBtn" color="accent" [value]="userRoles.Admin">
            <div id="adminLabel" class="t-12-500-s cursor-pointer team-status admin text-uppercase m-l-2">{{translations.team.admin | translate}}</div>
          </mat-radio-button>
          <p id="adminRightsDescription" class="t-14-400-s m-l-4 pl-4 m-b-3 m-t-1">{{translations.team.admin_rights_description | translate}}</p>
        }

        <mat-radio-button id="memberRadioBtn" color="accent" [value]="userRoles.User">
          <div id="memberLabel" class="t-12-500-s cursor-pointer team-status member text-uppercase m-l-2">{{translations.team.member | translate}}</div>
        </mat-radio-button>
        <p id="memberRightsDescription" class="t-14-400-s m-l-4 pl-4 m-b-3 m-t-1">{{translations.team.member_rights | translate}}</p>

        <mat-radio-button id="supportRadioBtn" color="accent" [value]="userRoles.Support">
          <div id="supportLabel" class="t-12-500-s cursor-pointer team-status support text-uppercase m-l-2">{{translations.team.support | translate}}</div>
        </mat-radio-button>
        <p id="supportRightsDescription" class="t-14-400-s m-l-4 pl-4 m-b-0 m-t-1">{{translations.team.support_rights_description | translate}}</p>
      </mat-radio-group>

      @if (billingPlansQuery.userLimitReached$ | async) {
        <div id="userLimitReachedWarningContainer" class="team-member-warning">
          <div class="add-user-icon">
            <mat-icon fontIcon="group_add"></mat-icon>
          </div>
          <div class="message">
            <p id="userLimitReachedWarningNote">
              {{
              translations.team.additional_team_members_warning | translate: {
              totalMembers: (billingPlansQuery.currentPlan$ | async)?.productMetadata.users,
              memberPrice: (billingPlansQuery.additionalUserBasePrices$ | async)?.amount | currency: 'USD' : 'symbol' : '1.0-2'
            }
            }}
          </p>
        </div>
      </div>
    }

  </mat-dialog-content>

  <mat-dialog-actions class="align-right">
    <button id="cancelBtn"
      mat-dialog-close
      mat-raised-button
      color="secondary"
      class="large">
      {{translations.common.cancel | translate}}
    </button>
    <button mat-raised-button
      type="submit"
      id="inviteBtn"
      color="primary"
      class="large"
      [class.in-progress]="inProgress"
      [class.user-limit-reached]="(inProgress && (billingPlansQuery.userLimitReached$ | async))"
      [disabled]="!teamMateForm?.valid || inProgress">
      @if (!inProgress) {
        <span>
          {{ (billingPlansQuery.userLimitReached$ | async)
          ? (translations.team.invite_teammate_additonal | translate: {
          price: (billingPlansQuery.additionalUserBasePrices$ | async)?.amount | currency: 'USD' : 'symbol' : '1.0-2'
          })
          : translations.team.invite_teammate | translate
          }}
        </span>
      } @else {
        <mat-icon class="m-l-1 full-width">
          <mat-spinner id="loader" color="primary" diameter="20"></mat-spinner>
        </mat-icon>
      }
    </button>
    @if (billingPlansQuery.userLimitReached$ | async) {
      <div class="charge-on-next-bill">
        <p>{{translations.team.charged_on_next_bill | translate}}</p>
      </div>
    }
  </mat-dialog-actions>
</form>
</div>
