<div class="flex-container align-middle cursor-pointer" [mat-menu-trigger-for]="profileMenu">
  <gf-user-avatar [userId]="(user$ | async)?.userId" [size]="profilePreviewType.SMALL"></gf-user-avatar>
  <mat-icon class="m-l-1" [class.hint]="isDarkHeader" fontIcon="keyboard_arrow_down"></mat-icon>
</div>

<mat-menu #profileMenu yPosition="below" xPosition="before">
  @if (user$ | async; as user) {
    <div class="grid-y">
      <p class="m-b-0 p-x-2 t-13-600-p text-truncate" [innerHTML]="user| userDisplayName : true"></p>
      <p class="m-b-0 p-x-2 t-12-400-h text-truncate">{{ user?.email }}</p>
    </div>
  }
  <mat-divider class="m-y-1"></mat-divider>
  @if ((userQuery.isSubscriptionStatusActive$ | async)) {
    <a
      mat-menu-item routerLink="/profile">
      <mat-icon class="secondary" fontIcon="person"></mat-icon>{{translations.profile.my_profile | translate}}
    </a>
  }
  @if (!(userQuery.isSupport$ | async)
    && (userQuery.isSubscriptionStatusActive$ | async)) {
    <a
      mat-menu-item routerLink="/team-settings/profile">
      <mat-icon class="secondary" fontIcon="miscellaneous_services"></mat-icon>{{translations.navigation.account_settings | translate}}
    </a>
  }
  @if (((userQuery.user$ | async)?.userId | isOwnerOrAdmin | async)
    && (userQuery.isSubscriptionStatusActive$ | async)) {
    <a
      mat-menu-item routerLink="/team-settings/plans-and-billing">
      <mat-icon class="secondary" fontIcon="credit_card"></mat-icon>{{translations.plans.plans_billing | translate}}
    </a>
  }
  <a mat-menu-item (click)="signOut()">
    <mat-icon class="secondary" fontIcon="login"></mat-icon>{{translations.profile.sign_out | translate}}
  </a>
  <mat-divider class="m-y-1"></mat-divider>
  <div class="grid-x m-t-2 m-x-2 m-b-1 opacity08">
    <a class="m-b-0 t-12-400-h text-decoration-none" href="https://www.graphicsflow.com/privacy-policy" target="_blank">{{translations.organization.privacy_policy | translate}}</a>
    <a class="m-b-0 t-12-400-h text-decoration-none" href="https://www.graphicsflow.com/terms-and-conditions" target="_blank">&nbsp;•&nbsp;{{translations.organization.terms_of_service | translate}}</a>
  </div>
</mat-menu>
