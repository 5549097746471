<div class='notification-container' [ngClass]="notification.type">
  <div class="p-a-2">
    <div class="flex-container">
      @switch (notification.type) {
        @case (notificationTypes.INPROGRESS) {
          <mat-spinner
            [diameter]="28" [strokeWidth]="2.8" color="progress">
          </mat-spinner>
        }
        @case (notificationTypes.SUCCESS) {
          <mat-icon
          class="snackbar-success-icon material-icons" fontIcon="check_circle"></mat-icon>
        }
        @case (notificationTypes.ERROR) {
          <mat-icon
          class="snackbar-error-icon material-icons" fontIcon="error"></mat-icon>
        }
        @case (notificationTypes.WARNING) {
          <mat-icon
          class="snackbar-warning-icon material-icons" fontIcon="error"></mat-icon>
        }
      }

      <div class="flex-child-auto m-x-2 text-truncate" matSnackBarLabel>
        <p class="t-14-500-p m-b-0">{{notification.title}}</p>
        <p class="t-14-400-s m-b-0">{{notification.message}}</p>
        @if (notification?.description) {
          <p
            [class.error-message]="notification.type === notificationTypes.ERROR"
          class="t-14-400-s m-b-0 m-t-1 break-all">{{notification?.description}}</p>
        }
        <ng-container *ngTemplateOutlet="notification?.templateRef"></ng-container>
      </div>

      <div class="snackbar-close-icon img-circle flex-container align-middle align-center cursor-pointer">
        <mat-icon class="mat-18 hint" matSnackBarAction (click)="snackBarRef.dismissWithAction()" fontIcon="clear"></mat-icon>
      </div>
    </div>
  </div>
</div>
