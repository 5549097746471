import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { toString as _toString, cloneDeep as _cloneDeep} from 'lodash-es';

import {
  ApiResponse,
  Art,
  ArtApproval,
  Collaborator,
  ArtApprovalItem,
  ArtApprovalPackage,
  CollaboratorRole,
  CollaboratorLink,
  CreateArtApprovalForm,
  ArtApprovalRequest,
  ArtApprovalStatistics,
  ArtApprovalStatus,
  BulkActionPostData,
  TimelineEvent,
  ID
} from '@graphics-flow/types';
import { StringHelpers } from 'shared/util';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ArtApprovalHttpService {

  constructor(private readonly httpService: HttpService) {}

  addArtToApproval(artIds: ID[], approvalId: ID, originalItemId: ID): Observable<ArtApprovalPackage> {
    let postData: BulkActionPostData = {
      artApprovalId: approvalId?.toString(),
      artIds
    };
    if(originalItemId) postData = { ... postData, originalItemId };
    return this.httpService.post(this.getUrl('AddArtToApproval'), postData).pipe(
      map((response: ApiResponse<ArtApprovalPackage>) => response.data)
    );
  }

  addStockArtToApproval(artIds: ID[], approvalId: ID, originalItemId: ID): Observable<ArtApprovalPackage> {
    const params = new FormData();
    params.append('artApprovalId', approvalId?.toString());
    if(originalItemId) {
      params.append('originalItemId', originalItemId.toString());
    }
    artIds.forEach((id: ID) => {
      params.append('artIdList', `{${id?.toString()}}`);
    });
    return this.httpService.post(this.getUrl('AddStockArtToApproval'), params).pipe(
      map((response: ApiResponse<ArtApprovalPackage>) => response.data)
    );
  }

  uploadArtToApproval(file: File, approvalId: ID, originalItemId: ID): Observable<ArtApprovalPackage> {
    const newArt = <Art>{
      name: file.name.substr(0, file.name.lastIndexOf('.')) // remove ext from file name
    };
    const params: FormData = new FormData();
    params.append('file', file, file.name);
    params.append('artJson', JSON.stringify(newArt));
    params.append('artApprovalId', _toString(approvalId));
    if(originalItemId) {
      params.append('originalItemId', originalItemId.toString());
    }
    return this.httpService.post(this.getUrl('UploadArtToApproval'), params).pipe(
      map((response: ApiResponse<ArtApprovalPackage>) => response.data)
    );
  }

  create(approval: ArtApproval): Observable<ArtApproval> {
    return this.httpService.post(this.getUrl('CreateArtApproval'), approval).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  update(approval: ArtApproval): Observable<ArtApproval> {
    return this.httpService.post(this.getUrl('UpdateArtApproval'), approval).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  updateArtApprovalItem(approvalId: ID, approvalItem: ArtApprovalItem, isWaterMarkUpdated: boolean, actionComment?: string): Observable<ArtApprovalPackage> {
    const params = new FormData();
    params.append('artApprovalId', _toString(approvalId));
    params.append('artApprovalItemId', _toString(approvalItem.artApprovalItemId));
    if (approvalItem.status) params.append('status', approvalItem.status);
    params.append('name', approvalItem.name);
    params.append('description', approvalItem.description || '');
    params.append('sortOrder', _toString(approvalItem.sortOrder));
    params.append('backgroundColor', _toString(approvalItem.backgroundColor));
    if (approvalItem.watermark) params.append('watermarkString', JSON.stringify(approvalItem.watermark));
    params.append('refreshWaterMark', _toString(isWaterMarkUpdated));

    if (actionComment) {
      params.append('comment', actionComment);
    }

    return this.httpService.post(this.getUrl('UpdateArtApprovalItem'), params).pipe(
      map((response: ApiResponse<ArtApprovalPackage>) => response.data)
    );
  }

  updateCollaboratorRole(collaboratorId: ID, role: CollaboratorRole): Observable<Collaborator> {
    const params = new FormData();
    params.append('collaboratorId', _toString(collaboratorId));
    params.append('role', role);
    return this.httpService.post(this.getUrl('UpdateCollaboratorRole'), params).pipe(
      map((response: ApiResponse<Collaborator>) => response.data)
    );
  }

  refreshApprovalItemWaterMark(approvalId: ID, approvalItemId: ID): Observable<ArtApproval> {
    const params = new FormData();
    params.append('artApprovalId', _toString(approvalId));
    params.append('artApprovalItemId', _toString(approvalItemId));
    return this.httpService.post(this.getUrl('RefreshItemWaterMark'), params).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  get(): Observable<ArtApproval[]> {
    return this.httpService.get(this.getUrl('GetArtApprovals')).pipe(
      map((response: ApiResponse<ArtApproval[]>) => response.data)
    );
  }

  getPackage(id: ID) {
    return this.httpService.get(this.getUrl(`GetArtApprovalPackage/${id}`)).pipe(
      map((response: ApiResponse<ArtApprovalPackage>) => response.data)
    );
  }

  getCollaborators(approvalId: ID): Observable<Collaborator[]> {
    const params = new FormData();
    params.append('artApprovalId', _toString(approvalId));
    return this.httpService.post(this.getUrl('GetCollaborators'), params).pipe(
      map((response: ApiResponse<Collaborator[]>) => response.data)
    );
  }

  addCommentToApproval(comment: string, approvalId: ID, approvalItemId: ID, parentCommentId?: ID): Observable<ArtApproval> {
    const params = new FormData();
    params.append('comment', comment);
    params.append('artApprovalId', _toString(approvalId));
    params.append('artApprovalItemId', _toString(approvalItemId));
    params.append('parentCommentId', _toString(parentCommentId));
    return this.httpService.post(this.getUrl('AddCommentToApproval'), params).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  addCollaboratorToApproval(approvalId: ID, name: string, email: string, message: string, role?: CollaboratorRole, collaboratorId?: ID, collaboratorLinkId?: ID, userShareableLinkPermission?: boolean): Observable<Collaborator> {
    const params = new FormData();
    params.append('collaboratorId', StringHelpers.toString(collaboratorId));
    params.append('collaboratorLinkId', StringHelpers.toString(collaboratorLinkId));
    params.append('name', StringHelpers.toString(name));
    params.append('message', StringHelpers.toString(message));
    params.append('email', StringHelpers.toString(email));
    params.append('role', StringHelpers.toString(role));
    params.append('artApprovalId', StringHelpers.toString(approvalId));
    params.append('userShareableLinkPermission', StringHelpers.toString(userShareableLinkPermission));
    return this.httpService.post(this.getUrl('AddCollaborator'), params).pipe(
      map((response: ApiResponse<Collaborator>) => response.data)
    );
  }

  deleteCommentFromApproval(approvalId: ID, commentId: ID): Observable<ArtApproval> {
    const params = new FormData();
    params.append('artApprovalId', _toString(approvalId));
    params.append('artApprovalCommentId', _toString(commentId));
    return this.httpService.post(this.getUrl('DeleteArtApprovalComment'), params).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  deleteArtApproval(artApproval: ArtApproval): Observable<ArtApproval> {
    const params = new FormData();
    params.append('artApprovalId', _toString(artApproval.artApprovalId));
    return this.httpService.post(this.getUrl('DeleteArtApproval'), params).pipe(
      // Reply is empty
      map(() => {
        return artApproval;
      })
    );
  }

  deleteArtApprovalItem(approvalId: ID, approvalItemId: ID): Observable<ArtApproval> {
    const params = new FormData();
    params.append('artApprovalId', _toString(approvalId));
    params.append('artApprovalItemId', _toString(approvalItemId));
    return this.httpService.post(this.getUrl('DeleteArtApprovalItem'), params).pipe(
      // Reply is empty
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  removeCollaborator(artApprovalId: ID, collaboratorId: ID, currentCollaboratorId?: ID): Observable<Collaborator> {
    const params = new FormData();
    params.append('artApprovalId', _toString(artApprovalId));
    params.append('collaboratorId', _toString(collaboratorId));
    if (currentCollaboratorId) {
      params.append('currentCollaborator', _toString(currentCollaboratorId));
    }

    return this.httpService.post(this.getUrl('RemoveCollaboratorFromArtApproval'), params).pipe(
      // Reply is empty
      map((response: ApiResponse<Collaborator>) => response.data)
    );
  }

  createShareLink(artApprovalId: ID): Observable<CollaboratorLink> {
    const params = new FormData();
    params.append('artApprovalId', _toString(artApprovalId));
    return this.httpService.post(this.getUrl('CreateShareableCollaboratorLink'), params).pipe(
      map((response: ApiResponse<CollaboratorLink>) => response.data)
    );
  }

  resendInviteToCollaborators(artApprovalId: ID, collaboratorId: ID = null): Observable<TimelineEvent> {
    const params = new FormData();
    params.append('artApprovalId', _toString(artApprovalId));
    if (collaboratorId) {
      params.append('collaboratorId', _toString(collaboratorId));
    }
    return this.httpService.post(this.getUrl('ResendInviteToCollaborators'), params).pipe(
      map((response: ApiResponse<TimelineEvent>) => response.data)
    );
  }

  sendUpdatesNotificationByMail(artApprovalId: ID): Observable<TimelineEvent> {
    const params = new FormData();
    params.append('artApprovalId', _toString(artApprovalId));
    return this.httpService.post(this.getUrl('SendUpdateEmail'), params).pipe(
      map((response: ApiResponse<TimelineEvent>) => response.data)
    );
  }

  searchArtApproval(searchText: string): Observable<ArtApproval[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('searchText', StringHelpers.toString(searchText));
    return this.httpService.get(this.getUrl('GetArtApprovals?'+ params.toString())).pipe(
      map((response: ApiResponse<ArtApproval[]>) => response.data)
    );
  }

  getExistingCollaborators(): Observable<string[]> {
    return this.httpService.get(this.getUrl('GetExistingCollaborators')).pipe(
      map((response: ApiResponse<string[]>) => response.data)
    );
  }

  createArtApprovalAndAddArt(artIds: ID[], approvalForm: CreateArtApprovalForm): Observable<Partial<ArtApproval>> {
    const postData: BulkActionPostData = {
      assigneeId: approvalForm.assigneeId?.toString(),
      artApprovalName: approvalForm.artApprovalName,
      description: approvalForm.description,
      artIds
    };
    return this.httpService.post(this.getUrl('CreateApprovalAndAddArt'), postData).pipe(
      map((response: ApiResponse<Partial<ArtApproval>>) => response.data)
    );
  }

  createArtApprovalAndAddStockArt(artIds: ID[], approvalForm: CreateArtApprovalForm): Observable<ArtApprovalPackage> {
    const params = new FormData();
    params.append('assigneeId', approvalForm.assigneeId?.toString());
    params.append('artApprovalName', approvalForm.artApprovalName);
    params.append('description', approvalForm.description);
    artIds.forEach((id: ID) => {
      params.append('stockArtIdList', `{${id?.toString()}}`);
    });
    return this.httpService.post(this.getUrl('CreateApprovalAndAddStockArt'), params).pipe(
      map((response: ApiResponse<ArtApprovalPackage>) => response.data)
    );
  }

  updateShareableLinkPermission(artApprovalId: ID, role: CollaboratorRole): Observable<boolean> {
    const params = new FormData();
    params.append('artApprovalId', artApprovalId.toString());
    params.append('role', role);
    return this.httpService.post(this.getUrl('/UpdateShareableLinkRole'), params).pipe(
      map((response: ApiResponse<boolean>) => response.data)
    );
  }

  getCollaboratorLink(collaboratorId: ID): Observable<Collaborator> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('collabLink', _toString(collaboratorId));
    return this.httpService.get(this.getUrl('GetCollaboratorLink?'+ params.toString())).pipe(
      map((response: ApiResponse<Collaborator>) => response.data)
    );
  }

  getArtApprovalSummary(activeFilters: ArtApprovalRequest): Observable<ApiResponse<ArtApproval[]>> {
    const postData: ArtApprovalRequest = _cloneDeep(activeFilters);
    postData.isGlobalFilter = activeFilters?.isGlobalFilter ?? false;

    return this.httpService.post(this.getUrl(`GetArtApprovalSummary`), postData).pipe(
      map((response: ApiResponse<ArtApproval[]>) => {
        return response;
      })
    );
  }

  getArtApprovalStatistics(status: ArtApprovalStatus): Observable<ArtApprovalStatistics> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('status', status);
    return this.httpService.get(this.getUrl('GetArtApprovalStatistics?'+ params.toString())).pipe(
      map((response: ApiResponse<ArtApprovalStatistics>) => response.data)
    );
  }

  updateArtApprovalStatus(artApprovalId: ID, status: ArtApprovalStatus): Observable<boolean> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('artApprovalId', artApprovalId?.toString());
    params.append('status', status);
    return this.httpService.post(this.getUrl('UpdateArtApprovalStatus?'+ params.toString())).pipe(
      map((response: ApiResponse<boolean>) => response.data)
    );
  }

  getAATimelineEvents(artApprovalId: ID): Observable<TimelineEvent[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('artApprovalId', artApprovalId?.toString());
    return this.httpService.get(this.getUrl('GetAATimelineEvents?' + params.toString())).pipe(
      map((response: ApiResponse<TimelineEvent[]>) => response.data)
    );
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`artapproval/${api}`);
  }
}
