<div id="folderMoveDialog">
  <div mat-dialog-title class="custom-dialog-title">
    <div class="text-truncate flex-child-auto">{{data.title | translate}}</div>
    <mat-icon class="mat-icon-close cursor-pointer" (click)="close()" fontIcon="close"></mat-icon>
  </div>
  <mat-dialog-content>
    @if (showFolderLoader) {
      <div class="flex-container align-center-middle loader-state">
        <loading-indicator [diameter]="30"></loading-indicator>
      </div>
    } @else {
      @if (folders$ | async; as folders) {
        <div #dialogElement
          class="folder-list-container p-t-1"
          [class.m-b-4]="windowService.smallDown$ | async">
          @if (windowService.mediumUp$ | async) {
            <div (click)="selectRootFolder()"
              [class.selected]="selectedFolder && !selectedFolder?.folderId"
              class="align-middle cursor-pointer flex-container move-to-top root-folder p-x-2 p-y-1">
              <mat-icon class="m-r-2" svgIcon="folder-icon"></mat-icon>
              <span class="t-14-600-p">{{translations.art.my_art | translate}}</span>
            </div>
            @for (folder of folders; track folder) {
              @if (!folder.trashed) {
                <gf-folder-move-list-item [folder]="folder" [sourceFolderIds]="sourceFolderIds"></gf-folder-move-list-item>
              }
            }
          } @else {
            <div class="folder-navigation m-b-4">
              @if (!!(folderMoveDialogQuery.navigationLevel$ | async)) {
                <div class="folder-level-wrap">
                  <mat-icon id="arrowBackIcon" class="m-r-1" (click)="goToParent()" fontIcon="arrow_back"></mat-icon>
                  <p id="parentFolderTxt" class="t-18-400-p text-truncate m-b-0">
                    {{ (folderMoveDialogQuery.navigationLevelFolder$ | async)?.name }}
                  </p>
                </div>
              } @else {
                <div class="folder-level-wrap folder-item flex-container m-x-3 p-l-2"
                  [class.selected]="selectedFolder && !selectedFolder?.folderId" (click)="selectRootFolder()">
                  <mat-icon id="folderIcon" class="m-r-2" svgIcon="folder-icon"></mat-icon>
                  <p id="parentFolderTxt" class="t-18-400-p text-truncate m-b-0">{{translations.art.my_art | translate}}</p>
                </div>
              }
            </div>
            <gf-mobile-view-folder-move-list-item [folders]="folders" [sourceFolderIds]="sourceFolderIds">
            </gf-mobile-view-folder-move-list-item>
          }
        </div>
      }
    }
  </mat-dialog-content>

  <mat-dialog-actions class="align-right"
    [class.border]="windowService.smallDown$ | async">
    <button id="cancelBtn"
      class="large"
      mat-dialog-close
      mat-raised-button
      color="secondary">
      <span class="t-15-600-p">{{data.cancelText | translate}}</span>
    </button>
    <button id="submitBtn"
      [disabled]="!selectedFolder"
      [mat-dialog-close]="selectedFolder"
      mat-raised-button
      color="primary"
      class="large">
      <span class="t-15-600-h" [class.white-color]="selectedFolder">{{data.confirmText | translate}}</span>
    </button>
  </mat-dialog-actions>
</div>
