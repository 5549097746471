<div class="folder-breadcrumbs flex-container align-middle" id="folder-breadcrumbs">
  @if (folders$ | async; as folders) {
    @if (showHomeIcon) {
      <button
        id="breadcrumbRoot"
        mat-button
        (click)="folderClick.emit(null)">
        <mat-icon fontIcon="home"></mat-icon>
      </button>
    } @else {
      <p id="breadcrumbRoot"
        class="t-18-400-p m-b-0 cursor-pointer outline-none"
        [class.detele-files]="isDeletedFiles"
        (click)="folderClick.emit(null)">
        {{(isDeletedFiles ? translations.art.deleted_files : translations.art.my_art) | translate}}
      </p>
    }
    @for (folder of folders; track folder) {
      @if ((windowService.largeUp$ | async) && !folder?.trashed) {
        <gf-folder-drop [parentFolderId]="folder.folderId">
          <ng-container [ngTemplateOutlet]="folderName"></ng-container>
        </gf-folder-drop>
      } @else {
        <ng-container [ngTemplateOutlet]="folderName"></ng-container>
      }
      <ng-template #folderName>
        <div class="flex-container align-middle">
          <mat-icon class="hint" fontIcon="chevron_right"></mat-icon>
          <p class="folder-name t-18-400-p dark-primary-text m-b-0 cursor-pointer outline-none" (click)="folderClick.emit(folder.folderId)">{{folder.name}}</p>
        </div>
      </ng-template>
    }
  }
</div>
