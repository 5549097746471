@if (stockArtListQuery.activeFilters$ | async; as activeFilter) {
  <mat-chip-listbox selectable="false">
    @if ((windowService.smallDown$ | async) || showDownloadFilterChips) {
      @if (activeFilter.stockArtFilterType === stockArtFilterType.Downloaded) {
        <mat-chip-option
          id="downloadedChip"
          [value]="stockArtFilterType.Downloaded"
          [selectable]="false"
          [removable]="true"
          (removed)="removeStockArtFilterType()">{{translations.common.downloaded | translate}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }
      @if (activeFilter.stockArtFilterType === stockArtFilterType.NotDownloaded) {
        <mat-chip-option
          id="notDownloadedChip"
          [value]="stockArtFilterType.NotDownloaded"
          [selectable]="false"
          [removable]="true"
          (removed)="removeStockArtFilterType()">{{translations.common.not_downloaded | translate}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }
      @if (activeFilter.stockArtFilterType === stockArtFilterType.HiddenManuallyInArtPortal) {
        <mat-chip-option id="hideInArtPortalChip"
          [value]="stockArtFilterType.HiddenManuallyInArtPortal" [selectable]="false" [removable]="true"
          (removed)="removeStockArtFilterType()">{{translations.common.manually_hidden_in_art_portal | translate}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }
      @if (activeFilter.stockArtFilterType === stockArtFilterType.Favorites) {
        <mat-chip-option id="favouritesChip"
          [value]="stockArtFilterType.Favorites" [selectable]="false" [removable]="true"
          (removed)="removeStockArtFilterType()">{{translations.common.favorites | translate}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }
    }
    @for (category of activeFilter.filterHeaders.categories; track category) {
      <mat-chip-option
        [value]="category.folderId"
        [selectable]="false"
        [removable]="true"
        (removed)="removeCategoryFilter(category)">{{ category.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }
    @for (category of activeFilter.filterHeaders.subCategories; track category) {
      <mat-chip-option
        [value]="category.folderId"
        [selectable]="false"
        [removable]="true"
        (removed)="removeSubCategoryFilter(category)">{{ category.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }
    @for (style of activeFilter.filterHeaders.styles; track style) {
      <mat-chip-option
        [value]="style"
        [selectable]="false"
        [removable]="true"
        (removed)="removeFilter('styles', style)">{{ style }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }
    @for (colorCount of activeFilter.filterHeaders.colorCount; track colorCount) {
      <mat-chip-option
        [value]="colorCount"
        [selectable]="false"
        [removable]="true"
        (removed)="removeFilter('colorCount', colorCount)">{{ colorCount }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }
    @for (artLibrary of activeFilter.filterHeaders?.artLibraries; track artLibrary) {
      <mat-chip-option
        [value]="artLibrary"
        [selectable]="false"
        [removable]="true"
        (removed)="removeFilter('artLibraries', artLibrary)"
        class="text-capitalize">{{ ("artLibraries." + artLibrary) | translate }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }
  </mat-chip-listbox>
}
