<div id="createArtApprovalDialog">
  <div mat-dialog-title class="header">
    <div class="create-art-approval-title text-truncate flex-child-auto">{{translations.design_requests.create_art_approval | translate}}</div>
    <mat-icon class="mat-icon-close cursor-pointer" color="black" (click)="close()" fontIcon="close"></mat-icon>
  </div>
  <mat-dialog-content>
    <form id="createArtApprovalFrom" [formGroup]="artApprovalForm" autocomplete="off" name="artApprovalForm" class="art-approval-form full-width">
      <div class="flex-container flex-dir-column">
        <label class="t-14-500-p dark-text mb-4">{{translations.common.assignee | translate}}</label>
        <mat-form-field appearance="fill" class="select-form-feild assignee-select">
          <mat-select id="assigneeSelect" [disableOptionCentering]="true" formControlName="assigneeId" [panelClass]="'p-y-1'">
            <mat-select-trigger>
              <div id="assignedUser" class="t-14-500-p flex-container align-left">
                <gf-teammate-avatar class="text-truncate"
                  [teammateId]="artApprovalForm?.get('assigneeId')?.value"
                  [size]="ImagePreviewType.SMALL"
                  [profilePicSize]="'small'"
                  [panelClass]="'align-center-middle'">
                </gf-teammate-avatar>
              </div>
            </mat-select-trigger>
            @for (id of ((teamQuery.activeTeam$ | async) | getTeamsInfo); track id) {
              <mat-option class="assignee-option" [value]="id">
                <div class="t-14-500-p flex-container align-left">
                  <gf-teammate-avatar [size]="ImagePreviewType.SMALL" [teammateId]="id" [type]="'user'"></gf-teammate-avatar>
                </div>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <label class="t-14-500-p dark-text m-t-2 mb-4">{{translations.design_requests.art_approval_name | translate}}</label>
        <mat-form-field appearance="fill" class="normal-input width-100 m-b-1">
          <input matInput type="text" id="artApprovalName" formControlName="artApprovalName" maxLength="132" autocomplete="off">
          @if (artApprovalForm.get('artApprovalName').hasError('required')) {
            <mat-error>{{translations.profile.first_name_required | translate}}</mat-error>
          }
        </mat-form-field>
        <label class="t-14-500-p dark-text m-t-1 mb-4">{{translations.common.description | translate}}</label>
        <mat-form-field appearance="fill" class="normal-input width-100 m-b-1 error-none">
          <textarea formControlName="description"
            id="description"
            class="art-approval-description overflow-hidden"
            matInput
            cdkTextareaAutosize
            #descriptionAutosize="cdkTextareaAutosize"
            name="description">
          </textarea>
        </mat-form-field>
        @if (data?.status === designRequestStatus.OPEN) {
          <mat-checkbox class="archive-checkbox m-t-2 t-14-500-p" formControlName="archive"><span class="dark-text" [innerHTML]="translations.design_requests.create_art_approval_archive | translate"></span></mat-checkbox>
        }
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="align-right border">
    <button id="cancelBtn"
      mat-raised-button
      class="large"
      color="secondary"
      mat-dialog-close>
      {{translations.common.cancel| translate}}
    </button>
    <button id="createArtApproval"
      mat-raised-button
      class="mat-focus-indicator large"
      color="primary"
      [disabled]="artApprovalForm.invalid"
      (click)="createApproval()">
      {{(data?.designRequestId ? translations.common.create : translations.common.create_and_add_files) | translate}}
    </button>
  </mat-dialog-actions>
</div>
