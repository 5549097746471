<div class="stock-art-filters" [ngClass]="alignmentClass">
  @if (showDownloadFilter && !(windowService?.smallDown$ | async)) {
    <gf-stock-art-type-filter></gf-stock-art-type-filter>
  }
  @if (((stockArtListQuery.activeStockArtType$ | async) !== StockArtType.Font) && !(windowService?.smallDown$ | async)) {
    @if (!isCustomStockArtFilter) {
      <gf-category-filter></gf-category-filter>
    }
    @if ((stockArtListQuery.activeStockArtType$ | async) !== StockArtType.ClipArt) {
      <!-- Styles on clip art aren't what we want right now -->
      <gf-stock-art-list-filter filterProperty="styles"
      [label]="translations.stock.style | translate"></gf-stock-art-list-filter>
    }
    @if (!isCustomStockArtFilter && ((billingPlansQuery.isGraphicsBuilderPartOfAddOn$ | async) || showCollectionFilter)) {
      <gf-stock-art-list-filter filterProperty="artLibraries"
      [label]="translations.stock.collection | translate"></gf-stock-art-list-filter>
    }
  }

  <mat-form-field
    class="filter-form-field"
    floatLabel="never"
    [class.search-input]="showDynamicSearchPlaceholder || isCustomStockArtFilter">
    <input matInput
      id="filterStockArt"
      name="filterStockArt"
      [placeholder]="( showDynamicSearchPlaceholder
      ? 'public_stock_art.search_' + (stockArtListQuery.activeStockArtType$ | async)
      : translations.common.filter_results ) | translate"
      [formControl]="searchControl">
    <mat-icon class="hint" matSuffix class="hint" fontIcon="search"></mat-icon>
  </mat-form-field>
</div>
