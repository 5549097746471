@if (showCustomizeStockArtOption) {
  <button
    id="customizeMenuBtn"
    mat-menu-item
    (click)="openStockArtDetails.emit()">
    {{translations.common.customize | translate}}
  </button>
}
<button mat-menu-item
  id="addArtToApprovalBtn"
  (click)="artActionsService.addStockArtToApproval(art?.artId, approvalMsg.artApprovalMsgRef);">
  {{translations.art.add_art_to_approval | translate}}
</button>

@if ((organizationQuery.isProPlan$ | async)) {
  @if (art.isHidden) {
    <button type="button" mat-menu-item id="showInArtPortalBtn"
      (click)="updateStockArtVisibility(false)">
      {{translations.public_catalog_view.show_in_art_portal | translate}}
    </button>
  } @else {
    @if (!(userQuery.isSupport$ | async)) {
      <button type="button" mat-menu-item id="hideInArtPortalBtn" (click)="updateStockArtVisibility(true)">
        {{translations.public_catalog_view.hide_in_art_portal | translate}}
      </button>
    }
  }
}

<mat-divider class="m-y-1"></mat-divider>
@for (extension of art?.artExtensions; track extension) {
  <button mat-menu-item
    (click)="downloadStockArt(art, extension.name)">
    {{translations.art.download_x | translate : { x: extension.extension | uppercase } }}
  </button>
}

<gf-add-to-approval-msg #approvalMsg></gf-add-to-approval-msg>
