<div class="color-picker" #dialogPopup>
  <div class="preset-area">
    @for (group of colorGroups; track group; let isLastGroup = $last) {
      @if ((group.Colors)?.length > 0) {
        @if (group.Label) {
          <div id="colorLabel" class="preset-label">{{group.Label}}</div>
        }
        <div class="preset-container flex-container">
          @for (Color of group.Colors.slice(0, 6); track Color; let index = $index) {
            <div id="presetColor-{{index}}"
              class="preset-color"
              [style.backgroundColor]="Color"
              [style.borderColor]="getBorderColor(Color)"
              [hidden]="Color === 'none'"
              (click)="setColorFromString(Color)">
            </div>
          }
          <div class="flex-child-grow"></div>
          @if (isLastGroup && allowClear) {
            <div id="clearColorActionTrigger" class="preset-color preset-color--transparent" (click)="clearColor()"></div>
          }
        </div>
      }
    }
  </div>
  <div #saturationAndBrightnessSlider
    id="saturationAndBrightnessSlider"
    [style.background-color]="hueSliderColor"
    class="saturation-lightness"
    [slider]
    [rgX]="1"
    [rgY]="1"
    (newValue)="setSaturationAndBrightness($event)">
    <div id="saturationAndBrightnessSliderCursor" [style.left.px]="slider.s" [style.top.px]="slider.v" class="cursor"></div>
  </div>
  <div id="colorPresetsContainer" class="box">
    <div id="selectedColorContainer" class="left">
      <div id="selectedColorBg" class="selected-color-background"></div>
      <div id="selectedColor" [style.background-color]="color"
        [style.borderColor]="getBorderColor(color)"
        class="selected-color">
      </div>
    </div>
    <div id="colorPickerContainer" class="right">
      <div id="colorPickerSlider" [slider] [rgX]="1" (newValue)="setHue($event)" class="hue" #hueSlider>
        <div id="colorPickerSliderCursor" [style.left.px]="slider.h" class="cursor"></div>
      </div>
      @if (showAlpha) {
        <div id="alphaColorPickerSlider" [slider] [style.background-color]="alphaSliderColor" [rgX]="1"
          (newValue)="setAlpha($event)" class="alpha" #alphaSlider>
          <div id="alphaColorPickerSliderCursor" [style.left.px]="slider.a" class="cursor"></div>
        </div>
      }
    </div>
  </div>
  <div id="colorPickerInputsContainer" class="color-picker__inputs">
    @if (!showCMYKPicker) {
      <div id="hslaInputElContainer" [hidden]="format!==2" class="hsla-text">
        <div class="box">
          <input id="h-valueInputEl" [text] type="number" pattern="[0-9]*" min="0" max="360" [rg]="360" (newValue)="setHue($event)"
            [value]="hslaText?.h"/>
          <input id="s-valueInputEl" [text] type="number" pattern="[0-9]*" min="0" max="100" [rg]="100" (newValue)="setSaturation($event)"
            [value]="hslaText?.s"/>
          <input id="l-valueInputEl" [text] type="number" pattern="[0-9]*" min="0" max="100" [rg]="100" (newValue)="setLightness($event)"
            [value]="hslaText?.l"/>
          @if (showAlpha) {
            <input id="a-valueInputEl" [text] type="number" pattern="[0-9]+([\.,][0-9]{1,2})?" min="0" max="1" step="0.1"
              [rg]="1" (newValue)="setAlpha($event)" [value]="hslaText?.a"/>
          }
        </div>
        <div id="hslaLabelContainer" class="box">
          <div id="h-label">H</div>
          <div id="s-label">S</div>
          <div id="l-label">L</div>
          @if (showAlpha) {
            <div id="a-label">A</div>
          }
        </div>
      </div>
      <div id="rgbaInputElContainer" [hidden]="format!==1" class="rgba-text">
        <div class="box">
          <input id="r-valueInputEl" [text] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setR($event)"
            [value]="rgbaText?.r"/>
          <input id="g-valueInputEl" [text] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setG($event)"
            [value]="rgbaText?.g"/>
          <input id="b-valueInputEl" [text] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setB($event)"
            [value]="rgbaText?.b"/>
          @if (showAlpha) {
            <input id="a-valueInputEl" [text] type="number" pattern="[0-9]+([\.,][0-9]{1,2})?" min="0" max="1" step="0.1"
              [rg]="1" (newValue)="setAlpha($event)" [value]="rgbaText?.a"/>
          }
        </div>
        <div id="rgbaLabelContainer" class="box">
            <div id="r-label">R</div>
            <div id="g-label">G</div>
            <div id="b-label">B</div>
          @if (showAlpha) {
            <div id="a-label">A</div>
          }
        </div>
      </div>
      <div id="hexInputElContainer" [hidden]="format!==0" class="hex-text">
        <div class="box">
          <input id="hexValueInputEl" [text] (newValue)="setColorFromString($event)" [value]="hexText"/>
        </div>
        <div id="hexLabelContainer" class="box">
          <div id="hexLabel">Hex</div>
        </div>
      </div>
    } @else {
      <div id="cmykInputElContainer" class="cmyk-text">
        <div class="box">
          <input id="cmykValueInputEl"
            #cmykPickerInputEl
            [text]
            (focusout)="setCmykFromString(cmykPickerInputEl.value)"
            [value]="cmykText"/>
        </div>
        <div id="cmykLabelContainer" class="box">
          <div id="cmykLabel">CMYK</div>
        </div>
      </div>
    }
    @if (!showCMYKPicker) {
      <div id="toggleColorFormat" class="type-policy" (click)="formatPolicy()"></div>
    }
  </div>
</div>
