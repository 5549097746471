<virtual-scroller #scroll [items]="dataSource" [enableUnequalChildrenSizes]="true" [parentScroll]="scrollingBlock" (vsEnd)="virtualScrollEnd($event)">
  @if (!(windowService.smallDown$ | async)) {
    <mat-table
      matSort
      #container
      class="art-list show-for-medium"
      [dataSource]="scroll.viewPortItems"
      [matSortActive]="(myArtSearchQuery.activeQueryParams$ | async)?.sortBy"
      [matSortDirection]="(myArtSearchQuery.activeQueryParams$  | async)?.orderDesc ? Order.DESC : Order.ASC"
      [trackBy]="trackTableFiles">
      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" class="img-col" mat-cell>
          @if (element?.artId) {
            <div class="art-img trans-bg position-relative" [class.non-image-preview]="element | isNoPreviewImage">
              <img #artImg [src]="(organizationQuery.activeOrganizationUri$ | async) | myArtImagePreview :(element.artId | art | async) : imagePreviewType.THUMBNAIL : false :false : 'png'" class="img-centered" [alt]="element?.name | fileName"/>
            </div>
          } @else {
            <mat-icon class="folder-img" svgIcon="folder-icon"></mat-icon>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{translations.common.name | translate}}</th>
        <td *matCellDef="let element" class="art-name" mat-cell>
          @if (element?.artId) {
            <div class="outline-none">
              <p class="m-b-0 t-13-600-p text-truncate">{{element.name}}</p>
              @if (!element?.customizedStockArtId) {
                <p class="m-b-0 t-12-300-p hint text-truncate">
                {{(element?.artExtensions[2]?.extension || element?.fileName) | fileExtension | uppercase}}</p>
              }
            </div>
          } @else {
            <p class="m-b-0 t-13-600-p text-truncate outline-none">
            {{element.name}}</p>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="creator">
        <th *matHeaderCellDef mat-header-cell>{{translations.common.creator | translate}}</th>
        <td *matCellDef="let element" class="list-view-col" mat-cell>
          <p class="m-b-0 text-truncate" [innerHTML]="element?.createdBy | user| async | userDisplayName : true"></p>
        </td>
      </ng-container>
      <ng-container matColumnDef="modifiedDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{translations.common.last_modified | translate}}
        </th>
        <td *matCellDef="let element" class="list-view-col" mat-cell> {{element.modifiedDate | date: 'dd MMM yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="size">
        <th *matHeaderCellDef mat-header-cell>{{translations.common.size | translate}}</th>
        <td *matCellDef="let element" class="size-col" mat-cell>
        {{ element?.fileStorageUsage ? ((element?.artExtensions[2]?.memorySize || element?.fileStorageUsage) | filesize : { base: 2, standard: 'jedec' } ) : '—'}} </td>
      </ng-container>
      <ng-container matColumnDef="favorite">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" class="size-col" mat-cell>
          @if (element.isFavorite) {
            <mat-icon class="mat-18 m-x-2 m-t-1" fontIcon="favorite"></mat-icon>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef='actions'>
        <th *matHeaderCellDef class="text-center" mat-header-cell></th>
        <td *matCellDef="let element" [matMenuTriggerFor]="folderMenu" class="col-actions text-center" mat-cell>
          <mat-icon class="dark-secondary-text cursor-pointer p-a-1 folder-actions" fontIcon="more_vert"></mat-icon>
          <mat-menu #folderMenu="matMenu">
            @if (element?.artId) {
              <button mat-menu-item>{{translations.art.add_art_to_approval | translate}}</button>
              <button mat-menu-item>{{translations.common.rename | translate}}</button>
              <button mat-menu-item>{{translations.common.move | translate}}</button>
              <mat-divider class="m-y-1"></mat-divider>
              <button mat-menu-item>{{translations.art.download_art | translate}}</button>
              <button mat-menu-item>{{translations.art.download_pdf | translate}}</button>
              <mat-divider class="m-y-1"></mat-divider>
              <button class="danger-color" mat-menu-item>{{translations.common.delete | translate}}</button>
            } @else {
              <button (click)="renameFolder.emit(element?.folderId)"
              mat-menu-item>{{translations.common.rename | translate}}</button>
              <button (click)="moveFolder.emit(element?.folderId)"
              mat-menu-item>{{translations.common.move | translate}}</button>
              <mat-divider class="m-y-1"></mat-divider>
              <button mat-menu-item>{{translations.common.download | translate}}</button>
              <mat-divider class="m-y-1"></mat-divider>
              <button class="danger-color" mat-menu-item>{{translations.common.delete | translate}}</button>
            }
          </mat-menu>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;"
        class="cursor-pointer"
        [class.selected]="artSelectionModel.isSelected(row?.artId)"
        [class.disabled-art]="(row | isNoPreviewImage) || (checkFileFormat && (row | isCSASupportedFile))"
        mat-row
      (click)="select(row)"></tr>
    </mat-table>
  }

  @if ((windowService.smallDown$ | async)) {
    <div #container class="art-list-mobile">
      @for (art of scroll.viewPortItems; track trackTableFiles($index, art)) {
        <div
          class="art-list-items flex-container cursor-pointer align-center-middle"
          [class.selected]="artSelectionModel.isSelected(art?.artId)"
          [class.disabled-art]="(art | isNoPreviewImage) || (checkFileFormat && (art | isCSASupportedFile))"
          (click)="select(art)">
          <div class="art-list-img-container">
            @if (art?.artId) {
              <div class="art-img trans-bg position-relative" [class.non-image-preview]="art | isNoPreviewImage">
                <img #artImg [src]="(organizationQuery.activeOrganizationUri$ | async) | myArtImagePreview :(art.artId | art | async) : imagePreviewType.THUMBNAIL : false :false : 'png'" class="img-centered" [alt]="element?.name | fileName"/>
              </div>
            } @else {
              <mat-icon class="folder-img" svgIcon="folder-icon"></mat-icon>
            }
          </div>
          <div class="flex-child-auto m-l-1 text-truncate">
            @if (art?.artId) {
              <div class="outline-none">
                <p class="m-b-0 t-13-600-p text-truncate">{{art.name}}</p>
                <div class="flex-container align-middle">
                  <p class="m-b-0 t-12-400-h text-truncate">
                    {{(art?.artExtensions[2]?.extension || art?.fileName) | fileExtension | uppercase}}
                  </p>
                  <div class="circle-division"></div>
                  <p class="m-b-0 t-12-400-h">{{art.modifiedDate | date: 'dd MMM yyyy'}}</p>
                </div>
              </div>
            } @else {
              <p class="mb-4 outline-none t-12-600-h text-truncate dark-text">
                {{art.name}}
              </p>
              <p class="m-b-0 t-12-400-h">{{art.modifiedDate | date: 'dd MMM yyyy'}}</p>
            }
          </div>
          @if (art?.artId && art?.isFavorite) {
            <mat-icon class="mat-18 m-x-2" fontIcon="favorite"></mat-icon>
          }
        </div>
      }
    </div>
  }

  @if (myArtSearchQuery.showInfiniteScrollLoader$ | async) {
    <div class="flex-container align-center-middle p-a-1">
      <loading-indicator [diameter]="28"
        [showInfiniteScrollLoader]="true"
        [panelClass]="'loader m-r-1'"
        class="infinite-loader">
      </loading-indicator>
    </div>
  }
</virtual-scroller>
