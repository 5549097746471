@if (stockArtListQuery.activeFilterHeaders$ | async; as activeFilters) {
  @if (stockArtListQuery.filterOptions$ | async; as filterOptions) {
    @if (!canShowClipArtMobileViewFilter) {
      <mat-form-field class="filter-form-field show-for-medium" floatLabel="never" color="accent">
        <mat-select multiple
          disableRipple
          panelClass="filter-select-panel"
          [placeholder]="label"
          [ngModel]="activeFilters[filterProperty]"
          (selectionChange)="onSelectionChange($event)">
          <mat-select-trigger class="multi-select-label-trigger">
            {{label}}
          </mat-select-trigger>
          @if (activeFilters[filterProperty]?.length) {
            <a
              id="clearAllSelected"
              mat-button
              color="accent"
              class="m-y-1 p-x-2 clear-all-btn"
              (click)="clearAllSelected()">
              {{translations.stock.clear_all_selected | translate}}
            </a>
          }
          @for (option of filterOptions[filterProperty]; track option) {
            <mat-option [value]="option" class="text-capitalize">
              <ng-container *ngTemplateOutlet="filterLabel; context:{option: option}"></ng-container>
            </mat-option>
          }
        </mat-select>
        <mat-icon class="hint" matSuffix fontIcon="expand_more"></mat-icon>
      </mat-form-field>
    }
    <!--  Style Filter in mobile view-->
    @if ((windowService?.smallDown$ | async) || canShowClipArtMobileViewFilter) {
      <div>
        @for (option of filterOptions[filterProperty]; track option) {
          <div class="m-t-2">
            <mat-checkbox class="style-filter t-16-500-s text-capitalize"
              [checked]="(activeFilters[filterProperty])?.includes(option) || selectedFilters.includes(option)"
              (change)="setSelectedFilters(option, $event.checked)">
              <ng-container *ngTemplateOutlet="filterLabel; context:{option: option}"></ng-container>
            </mat-checkbox>
          </div>
        }
      </div>
    }
  }
}

<ng-template #filterLabel let-option="option">
  @if (filterProperty !== 'artLibraries') {
    {{option}}
  }
  @if (filterProperty === 'artLibraries') {
    {{ ("artLibraries." + option) | translate }}
  }
</ng-template>
