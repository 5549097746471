import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule } from '@angular/core';
import { SharedUtilsModule } from 'shared/util';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationComponent } from './components/notification-component/notification.component';
import { ColorPickerModule } from './components/color-picker/color-picker.module';
import { ColorPickerDropdownModule } from './components/color-picker-dropdown/color-picker-dropdown.module';
import { BrokenImageDirective } from './components/broken-image.directive';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { AutofocusDirective } from './directives/autofocus.directive';
import { StockArtSearchNoResultComponent } from './components/stock-art-search-no-result/stock-art-search-no-result.component';
import { GenericWarningDialogComponent } from './components/generic-warning-dialog/generic-warning-dialog.component';
import { GenericDataRemovalDailogComponent } from './components/generic-remove-dialog/generic-remove-dialog.component';

const COMPONENTS = [
  BrokenImageDirective,
  LoadingIndicatorComponent,
  NotificationComponent,
  GenericDialogComponent,
  AutofocusDirective,
  StockArtSearchNoResultComponent,
  GenericWarningDialogComponent,
  GenericDataRemovalDailogComponent
];

const MODULES = [
  ColorPickerModule,
  ColorPickerDropdownModule,
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    TranslateModule,
    SharedUtilsModule
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES
  ]
})
export class SharedUiModule {}
