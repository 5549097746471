@if (artId | art | async; as art) {
  <div
    class="art-detail-image-container width-100 height-100 position-relative"
    [style.backgroundColor]="background ? background : null"
    [class.trans-bg]="!background">
    @if (art?.stockArtType && !art?.customizedStockArtId) {
      @if (art?.stockArtType !== stockArtType.Font) {
        <img [src]="((art?.stockArtOrgId || art?.assignedOrganizationId) | imagePreview : art.assetId : imagePreviewType : hasWatermark : true : (art | artExtension : 'png'))"
          class="width-100 height-100" fetchpriority="auto" loading="auto" [alt]="art?.name | fileName"/>
      } @else {
        <img id="fontPreviewImg" class="width-100 height-100" [src]="art | fontPreviewUrl : 'full' : art?.stockArtOrgId" [alt]="art?.name | fileName" fetchpriority="auto" loading="auto"/>
      }
    } @else {
      <img #artImage id="stockArt" [src]="(organizationQuery.selectActiveId() | async) | myArtImagePreview : art : imagePreviewType : hasWatermark : false : (art | artExtension : 'png'): watermarkId"
        class="width-100 height-100"  [alt]="art?.name | fileName" fetchpriority="auto" loading="auto"/>
    }
    <!-- hide for now GF-514 -->
    <!-- <div *ngIf="showTools" class="flex-container img-controls">
    <button mat-icon-button class="mat-48">
      <mat-icon>aspect_ratio</mat-icon>
    </button>
    <mat-divider class="m-a-1" [vertical]="true"></mat-divider>
    <button mat-icon-button class="mat-48">
      <mat-icon>center_focus_strong</mat-icon>
    </button>
    <button mat-icon-button class="mat-48">
      <mat-icon fontIcon="zoom_in"></mat-icon>
    </button>
    <button mat-icon-button class="mat-48">
      <mat-icon>zoom_out</mat-icon>
    </button>
    <mat-divider class="m-a-1" [vertical]="true"></mat-divider>
    <button mat-icon-button class="mat-48">
      <mat-icon>print</mat-icon>
    </button>
    <button mat-icon-button class="mat-48">
      <mat-icon fontIcon="login"></mat-icon>
    </button>
  </div> -->
</div>
}
