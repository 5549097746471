<form [formGroup]="newTagFromGroup" class="m-b-1">
  @if (tags?.length) {
    <div class="m-b-1">
      <mat-chip-listbox>
        @for (tag of tags; track tag) {
          <mat-chip-option class="max-width-100" [class.clickable-chips]="allowFilterAction" [removable]="true" [selectable]="false" (removed)="removeTagFromArt(tag)" (click)="applyFilter(tag)">
            <div class="text-truncate" [title]="(tag | getTagByCanonicalName | async)?.displayName">{{(tag | getTagByCanonicalName | async)?.displayName | truncate : 30}}</div>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        }
      </mat-chip-listbox>
    </div>
  }

  <mat-form-field appearance="outline" floatLabel="never" class="width-100 error-none border-input">
    <input matInput type="text" formControlName="newTag" id="newTagInput"
      [placeholder]="translations.tag.add_tag_placeholder | translate" [matAutocomplete]="auto" maxlength="64">
    <mat-autocomplete #auto="matAutocomplete">
      <div [class.hide]="!((filteredOptions | async)?.length && newTagFromGroup.get('newTag').value)" class="m-y-1 tag-options-lists">
        @for (option of (filteredOptions | async); track option) {
          <mat-option class="autocomplete-option" [value]="option.canonicalName" (click)="addTagToArt(option.canonicalName)">
            {{option.displayName}}
          </mat-option>
        }
      </div>
      @if (!hasTagAlreadyExists && newTagFromGroup.get('newTag').value && (filteredOptions | async)?.length) {
        <mat-divider></mat-divider>
      }
      @if (!hasTagAlreadyExists && newTagFromGroup.get('newTag').value?.trim()) {
        <div class="my-6">
          <mat-option class="autocomplete-option" [value]="newTagFromGroup.get('newTag').value"
            (click)="createAndAddTag(newTagFromGroup.get('newTag').value)">
            <div class="flex-container align-middle">
              <mat-icon fontIcon="add"></mat-icon>
              <div class="create-option text-truncate">
                {{translations.common.create | translate}}
                <span class="font-normal">"{{newTagFromGroup.get('newTag').value}}"</span>
              </div>
            </div>
          </mat-option>
        </div>
      }
    </mat-autocomplete>
  </mat-form-field>
  <div class="mt-4 t-12-300-p">
    {{translations.tag.use_tags_to_easily_group_and_filter_art_files | translate}}
  </div>
</form>
