import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ArtApprovalComment, Collaborator, User } from '@graphics-flow/types';
import { ApprovalDetailQuery } from '@graphics-flow/util';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'canDeleteComment'
})

export class CanDeleteCommentPipe implements PipeTransform {

  constructor(private readonly approvalDetailQuery: ApprovalDetailQuery) {
  }

  transform(comment: ArtApprovalComment, replies?: ArtApprovalComment[]): Observable<boolean> {
    return this.approvalDetailQuery.identification$.pipe(
      map(([collaborator, user]: [ Collaborator, User]) => {
        const haveReplies: boolean = replies?.length > 0;
        const isOwner: boolean = comment?.collaboratorId ? comment.collaboratorId === collaborator?.collaboratorId : comment.createdBy === user.userId;
        return !haveReplies && isOwner;
      })
    );
  }
}
