@if ((userId | user | async); as user) {
  <div class="flex-container align-center-middle"
    matTooltipClass="mat-tooltip-below"
    [matTooltip]="user.email" #tooltip="matTooltip" (click)="tooltip.toggle()">
    @if ((!imageUrl && !user?.profileAssetExts?.length)) {
      <div
        [className]="'avatar rounded cursor-pointer '+ profilePicSize"
        [ngClass]=" user | userDisplayName | shortName | iconBackground">
        {{user | userDisplayName | shortName}}
      </div>
    } @else {
      @if (imageUrl) {
        <div
          [className]="'avatar img-preview rounded cursor-pointer '+ profilePicSize" [style.background-image]="'url('+ imageUrl + ')'">
        </div>
      } @else {
        <div [className]="'avatar img-preview rounded cursor-pointer '+ profilePicSize" [style.background-image]="'url('+ userProfilePicturePipe.transform(user, size) +')'"></div>
      }
    }
    <div></div>
    @if (showName) {
      <div>
        <div class="t-14-600-p p-l-1" [innerHTML]="user | userDisplayName : true"></div>
        <div class="t-14-400-s p-l-1">{{user.email }}</div>
      </div>
    }
  </div>
}
