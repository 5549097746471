<div class="footer flex-container align-justify flex-child-auto m-y-3">
  <div class="flex-container flex-dir-column all-rights">
    @if (!blankFooter) {
      <div class="links-wrapper t-12-500-s grid-x grid-margin-x">
        <span class="cell shrink cursor-pointer" (click)="organizationActionService.openTermsAndConditionsDialog()">{{translations.organization.terms_and_conditions | translate}}</span>
        <!--
        <span class="cell shrink">{{translations.organization.privacy_policy | translate}}</span>
        -->
        <span class="cell shrink cursor-pointer" (click)="organizationActionService.openColorDisclaimerDialog()" >{{translations.organization.color_disclaimer | translate}}</span>
        <!--
        <span class="cell shrink cursor-pointer" (click)="organizationActionService.openContactDialog()" >{{translations.common.contact_shop | translate}}</span>
        -->
      </div>
    }
    <div class="t-12-500-h m-t-1">
      {{translations.common.all_right_reserved | translate: { year: date.getFullYear(), name: organization?.name || organizationName || '' } }}
    </div>
  </div>
  @if (!((organizationQuery.organization$ | async)?.privateBrandingEnabled)) {
    <div class="flex-container flex-dir-column align-center-middle">
      <span class="t-12-500-h">{{translations.common.powered_by | translate}}</span>
      <img [src]="'/assets/images/logo-graphics-flow.svg' | addDomain" class="logo-footer" [alt]="translations.common.graphics_flow_logo | translate"/>
    </div>
  }
</div>
