@if (teammate$ | async; as teammate) {
  <div class="flex-container flex-1 text-truncate align-middle" [ngClass]="panelClass">
    @if (showFullInfo) {
      <div class="flex-container flex-1 text-truncate align-middle">
        <ng-template [ngTemplateOutlet]="avatar"></ng-template>
        <div class="ml-4 text-truncate">
          <p id="teammateName" class="t-14-600-p m-b-0 text-truncate assignee-avatar-name">{{teammate | userDisplayName: false}}</p>
          <p id="teammateEmail" class="t-14-400-h m-b-0 text-truncate">{{teammate?.email}}</p>
        </div>
      </div>
    } @else {
      <ng-template [ngTemplateOutlet]="avatar"></ng-template>
      <span id="teammateName" class="text-truncate assignee-avatar-name" [innerHTML]="teammate | userDisplayName : true : showInActive"></span>
    }
  </div>
}

<ng-template #avatar>
  @if (!nameOnly && teammate$ | async; as teammate) {
    <div class="teammate-profile">
      @if ((!imageUrl && !teammate?.profileAssetGuid)) {
        <div
          id="teammateShortName"
          [ngClass]=" teammate | userDisplayName | shortName | iconBackground"
          [className]="'avatar rounded cursor-pointer '+ profilePicSize"
          #tooltip="matTooltip"
          matTooltipClass="mat-tooltip-below"
          [matTooltip]="teammate.email"
          (click)="tooltip.toggle()">
          {{teammate | userDisplayName | shortName}}
        </div>
      } @else {
        @if (imageUrl) {
          <div
            id="teammateProfileImage"
            [className]="'avatar img-preview rounded cursor-pointer '+ profilePicSize" [style.background-image]="'url('+ imageUrl + ')'">
          </div>
        } @else {
          <div id="teammateProfileGradient" [className]="'avatar img-preview rounded cursor-pointer '+ profilePicSize" [style.background-image]="'url('+ userProfilePicturePipe.transform(teammate, size) +')'"></div>
        }
      }
    </div>
  }
</ng-template>
