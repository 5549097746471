@for (folder of children$ | async; track folder) {
  <div [class.selected]="(folderMoveDialogQuery.selectedFolder$ | async)?.folderId === folder.folderId"
    class="folder-item flex-container align-middle m-x-3 p-l-2 p-r-1">
    <div class="folder-name flex-1 flex-container align-middle text-truncate"
      (click)="setSelectedFolder(folder?.folderId)">
      <mat-icon class="m-r-2" svgIcon="folder-icon"></mat-icon>
      <span class="t-14-600-p flex-1 text-truncate">{{folder.name}}</span>
    </div>
    @if (folder | isDescendantAvailable: this.sourceFolderIds) {
      <div
        class="flex-container align-middle align-center">
        @if (showFolderLoaderById === folder?.folderId) {
          <mat-spinner
            [diameter]="20"
            [strokeWidth]="2"
            color="primary"
            class="folder-loading-state">
          </mat-spinner>
        } @else {
          <button [class.cursor-none]="!!showFolderLoaderById" mat-icon-button (click)="toggleExpanded(folder?.folderId)">
            <mat-icon class="hint" fontIcon="keyboard_arrow_right"></mat-icon>
          </button>
        }
      </div>
    }
  </div>
}
