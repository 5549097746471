<div class="grid-x hide-for-tiny-only storage-status align-center-middle" [class.almost-used]="type === usageLevel.ALMOST_USED"
  [class.usage-full]="type === usageLevel.USAGE_FULL">
  <div class="grid-x align-center-middle">
    <mat-icon class="m-r-1" [class.error-color]="type === usageLevel.USAGE_FULL" fontIcon="error"></mat-icon>
    <div class="header-section">
      <p id="usageHeader" class="m-b-0 t-14-600-s bold" [class.error-color]="type === usageLevel.USAGE_FULL">{{header}}</p>
      <p id="usageSubHeader" class="m-b-0 t-14-400-s ml-6"
      [class.error-color]="type === usageLevel.USAGE_FULL">{{subHeader}}</p>
    </div>
  </div>
  <div class="flex-child-auto"></div>
  <div class="grid-x align-center-middle">
    <div class="m-r-2 text-end">
      <p id="usageInfo" class="m-b-0 t-14-400-p" [innerHTML]="usage"></p>
      @if (resetInfo) {
        <p id="usageResetInfo" class="m-b-0 t-12-500-s reset-info" >{{resetInfo}}</p>
      }
    </div>
    <div #updateSubscriptionTooltip="matTooltip" (click)="updateSubscriptionTooltip.toggle()"
      matTooltipClass="mat-tooltip-below"
      matTooltip="{{translations.common.you_do_not_have_permission_to_make_plan_changes | translate}}"
      matTooltipDisabled="{{!(disableUpdateBtn$ | async)}}" position="above">
      <button id="updateBtn"
        mat-raised-button
        class="notification-btn"
        [disabled]="disableUpdateBtn$ | async"
        [routerLink]="['/plans-and-billing/update-subscription']">
        {{updateButtonName}}
      </button>
    </div>
  </div>
</div>
<div class="grid-y show-for-tiny-only storage-status" [class.almost-used]="type === usageLevel.ALMOST_USED" [class.usage-full]="type === usageLevel.USAGE_FULL">
  <div class="grid-x" [class.align-center-middle]="!isScrolledFromTop">
    <mat-icon [class.error-color]="type === usageLevel.USAGE_FULL" fontIcon="error"></mat-icon>
    <div class="header-section flex-1 align-center-middle">
      <p id="usageHeader" class="t-14-600-s m-b-0 text-center"
      [class.error-color]="type === usageLevel.USAGE_FULL">{{header}}</p>
      <p id="usageSubHeader" class="t-14-400-s m-b-1" [class.error-color]="type === usageLevel.USAGE_FULL"
        [class.hide]="isScrolledFromTop"
      [class.display-block]="!isScrolledFromTop">{{subHeader}}</p>
    </div>
  </div>
  <div [class.hide]="isScrolledFromTop" [class.display-block]="!isScrolledFromTop">
    <p id="usageInfo" class="t-14-400-p text-center" [class.m-b-0]="resetInfo" [innerHTML]="usage"></p>
    @if (resetInfo) {
      <p id="usageResetInfo" class="t-12-500-s m-b-1 reset-info text-center">{{resetInfo}}</p>
    }
    <div class="align-center flex-container"
      matTooltipClass="mat-tooltip-below"
      #planUpdateTooltip="matTooltip" (click)="planUpdateTooltip.toggle()"
      matTooltip="{{translations.common.you_do_not_have_permission_to_make_plan_changes | translate}}"
      matTooltipDisabled="{{!disableUpdateBtn$ | async}}" position="above">
      <button id="updateBtn"
        mat-raised-button
        class="notification-btn"
        [disabled]="disableUpdateBtn$ | async"
        [routerLink]="['/plans-and-billing/update-subscription']">
        {{updateButtonName}}
      </button>
    </div>
  </div>
</div>
