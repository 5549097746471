export const downloadLimitExceedErrorCode = '1-saGUCn';
export const storageLimitExceedErrorCode = '1-mJ6nyC';
export const corelSessionExpiredCode = '1-hTu69B';

export const unauthorizedErrorCodes: number[] = [401, 403];

// List of errors that will allows user to keep editing CSA.
// '1-mo9bvK' - Error code for PDF with multiple pages uploaded in CSA.
// '1-f7ipu9' - Error code for custom upload art fails in CSA.
export const CSA_ERRORS_ALLOWS_EDITING: string[] = ['1-mo9bvK', '1-f7ipu9'];

// Error Code for File not Found.
export const fileNotAvailableErrorCode = '1-zNJkNB';
// Error Code for replacing same clipart
export const InvalidReplacementErrorCode = '1-fg86Hu';
