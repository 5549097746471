<ng-template cdk-portal #myArtFileUploadTemplate="cdkPortal">
  <div class="file-upload-panel grid-y">
    <!-- panel header -->
    <div class="file-upload-panel-header grid-x align-center-middle">

      <mat-icon class="close-panel cursor-pointer" (click)="myArtUploaderService.hidePanel()" fontIcon="chevron_left"></mat-icon>
      @if ((myArtUploaderService.isUploadPreparing$ | async)) {
        <p
          class="t-16-500-p white-color m-b-0 flex-child-auto text-center">
          {{translations.art.preparing_upload | translate}}
        </p>
      } @else {
        @if (!(myArtUploaderService.isQueueProcessed$ | async)) {
          <p
          class="t-16-500-p white-color m-b-0 flex-child-auto text-center">{{ (myArtUploaderService.pendingAndInprogressFileCount$ | async) | i18nPlural: fileUploadingMessageMap | translate : {x: myArtUploaderService.pendingAndInprogressFileCount$ | async} }}</p>
        } @else {
          <p class="t-16-500-p white-color m-b-0 flex-child-auto text-center">{{ (myArtUploaderService.successFilesCount$ | async) | i18nPlural : fileUploadMessageMap | translate : {x : myArtUploaderService.successFilesCount$ | async} }}</p>
        }
      }
    </div>
    <!-- end of panel header -->

    <!-- cancell all button section -->
    @if (!(myArtUploaderService.isQueueProcessed$ | async)) {
      <div class="stop-upload-wrap flex-container align-right">
        <span class="t-13-500-h cursor-pointer"
          [class.link-color]="(myArtUploaderService.isPendingFilesThere$ | async) || (myArtUploaderService.isUploadPreparing$ | async)"
          [class.cursor-not-allowed]="!(myArtUploaderService.isPendingFilesThere$ | async) && !(myArtUploaderService.isUploadPreparing$ | async)"
          (click)="myArtUploaderService.cancelAll()">
          {{ translations.fileupload.stop_upload | translate}}
        </span>
      </div>
    }
    <!-- end of cancell all button section -->

    <!-- error counts section -->
    @if ((myArtUploaderService.failedFilesCount$ | async) > 0) {
      <div class="error-msg-wrap flex-container align-justify align-middle">
        <span class="t-13-500-h error-message">{{ (myArtUploaderService.failedFilesCount$ | async) | i18nPlural : fileUploadFailMessage | translate : {x: (myArtUploaderService.failedFilesCount$ | async)} }}</span>
        <mat-icon class="m-r-2 error-icon" fontIcon="error"></mat-icon>
      </div>
    }
    <!-- end of error counts section -->

    <!-- every file status list row section -->
    <div class="file-list grid-y">
      <!-- file status section -->
      @for (file of (queue$ | async); track file; let i = $index; let isLast = $last) {
        <div class="file-status grid-x align-center-middle m-x-3 m-y-1" id="file-{{i}}"
          [class.cursor-pointer]="file.status === fileStatus.Success && file.showFolder"
          (click)="openFile(file)">
          <div class='cell small-1'>
            @switch (file.status) {
              @case (fileStatus.Pending) {
                <mat-icon class="material-icons-outlined hint" fontIcon="image"></mat-icon>
              }
              @case (fileStatus.Error) {
                <mat-icon class="error-icon" fontIcon="error"></mat-icon>
              }
              @case (fileStatus.Success) {
                <mat-icon class="success-icon" fontIcon="check_circle"></mat-icon>
              }
              @case (fileStatus.Progress) {
                <mat-spinner color="progress" diameter="25"></mat-spinner>
              }
            }
          </div>
          <div class="cell grid-y p-l-2"
            [class.small-10]="file.status === fileStatus.Pending"
            [class.small-11]="file.status === fileStatus.Error || file.status === fileStatus.Success || file.status === fileStatus.Progress">
            @if (file?.showFolder) {
              @if (file?.folderId | folder | async; as folder) {
                <span class="flex-container t-12-400-s">
                  <mat-icon class="mat-18 hint mr-4">folder_open</mat-icon>{{folder?.name}}
                </span>
              }
            }
            <span class="t-14-500-p text-wrap">{{file?.file?.name}}</span>
            <span class="t-12-400-h">{{file?.file.size | filesize : { base: 2, standard: 'jedec' } }}</span>
            @if (file.status === fileStatus.Error) {
              <span class="error-message t-12-400-h">
                <strong>{{translations.common.error | translate}}: </strong>{{file?.errorMsg}}</span>
              }
            </div>
            <div class="cell" [class.small-1]="file.status === fileStatus.Pending">
              @if (file.status === fileStatus.Pending) {
                <button mat-icon-button class="mat-icon-btn cancel-upload-item" (click)="file.cancel()">
                  <mat-icon class="close" fontIcon="close"></mat-icon>
                </button>
              }
            </div>
          </div>
          @if (!isLast) {
            <mat-divider></mat-divider>
          }
        }
        <!-- end of file status section -->
      </div>

      @if ((myArtUploaderService.isUploadPreparing$ | async)) {
        <div
          class="flex-container grid-y align-center-middle upload-loading">
          <mat-spinner color="progress" diameter="25"></mat-spinner>
          <p class="m-t-2">{{translations.art.preparing_your_upload | translate}}</p>
        </div>
      }
      <!-- end of every file status list row section -->
    </div>
  </ng-template>
