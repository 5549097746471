<div class="flex-container align-top p-l-3 p-y-3  p-r-1">
  @if (comment.createdBy) {
    <gf-user-avatar [userId]="comment.createdBy" [size]="imagePreviewType.SMALL" [profilePicSize]="'small'"></gf-user-avatar>
  } @else {
    <gf-collaborator-avatar [collaborator]="comment.collaboratorId | collaborator | async" [size]="'medium'"></gf-collaborator-avatar>
  }
  <div class="flex-container flex-dir-column flex-child-auto comment-container">
    <div class="approval-comment-header flex-container align-center-middle p-a-1">
      <div class="flex-container flex-dir-column flex-child-auto label-container">
        @if (comment.createdBy) {
          <label class="t-14-600-p text-truncate"
            [title]="comment.createdBy | user | async | userDisplayName : true"
          [innerHTML]="comment.createdBy | user | async | userDisplayName : true"></label>
        } @else {
          <label class="t-14-600-p text-truncate">{{(comment.collaboratorId | collaborator | async)?.email}}</label>
        }
        <!--TODO do this in a decent way-->
        <label class="t-14-400-h">{{comment.createdDate | fromNowDate}}</label>
      </div>
      @if (comment | canDeleteComment: replies | async) {
        <mat-icon class="cursor-pointer" [matMenuTriggerFor]="addActions" fontIcon="more_vert"></mat-icon>
        <mat-menu #addActions="matMenu">
          <button class="danger-color" mat-menu-item (click)="deleteComment.emit(comment.commentId)">{{translations.common.delete | translate}}</button>
        </mat-menu>
      }
    </div>
    <span class="t-14-400-p m-y-1 m-r-3 text-wrap">
      {{comment.comment}}
    </span>
    @if (!!comment.actionCommentStatus) {
      <div class="action-comment-wrap grid-y m-y-2 t-12-400-h align-center">
        <div class="action-status">
          <span class="text-capitalize">{{comment.actionCommentStatus}}</span> by
          @if (!!comment?.createdBy) {
            <span
              [title]="comment.createdBy | user | async | userDisplayName : true"
            [innerHTML]="comment.createdBy | user | async | userDisplayName : true"></span>
          }
          @if (!!comment?.collaboratorId) {
            <span>{{(comment.collaboratorId | collaborator | async)?.email}}</span>
          }
        </div>
        <span class="t-12-400-h">{{comment.createdDate | date : 'MMMM dd, yyyy'}}</span>
      </div>
    }
    <div class="replies-wrapper">
      @for (reply of replies; track reply) {
        <gf-approval-comment-reply [reply]="reply" (deleteComment)="deleteComment.emit($event)">
        </gf-approval-comment-reply>
      }
    </div>
    @if (canReply) {
      <div class="flex-child-shrink m-r-2" [class.reply-btn-disabled]="disabled">
        <button mat-flat-button
          disableRipple
          class="p-x-0"
          [disabled]="disabled"
          (click)="showReply = !showReply">
          <span class="mr-4" [class.t-14-400-p]="showReply"
            [class.t-14-400-h]="!showReply">
            {{translations.common.reply | translate}}
          </span>
          <mat-icon class="secondary" fontIcon="keyboard_arrow_down"></mat-icon>
        </button>
        @if (showReply) {
          <gf-comment-input (commented)="commented.emit($event)"></gf-comment-input>
        }
      </div>
    }
  </div>
</div>
<mat-divider></mat-divider>
