@if (folder) {
  <div [class.selected]="(folderMoveDialogQuery.selectedFolder$ | async)?.folderId === folder.folderId"
    class="folder-item flex-container align-middle p-a-1 cursor-pointer">
    <div (click)="toggleExpanded()" [class.invisible]="!(folder | isDescendantAvailable: this.sourceFolderIds)"
      class="expanded-toggler flex-container align-middle align-center">
      <mat-icon class="hint">{{ expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
    </div>
    <div class="folder-name flex-1 flex-container align-middle text-truncate"
      (click)="folderMoveDialogService.setSelectedFolderId(folder?.folderId)">
      <mat-icon class="m-r-2" svgIcon="folder-icon"></mat-icon>
      <span class="t-14-600-p flex-1 text-truncate">{{folder.name}}</span>
    </div>
    @if (showFolderLoader) {
      <mat-spinner [diameter]="20" [strokeWidth]="2.8" color="primary"></mat-spinner>
    }
  </div>
  <div [class.hide]="!expanded" class="subfolder-container">
    @for (child of (children$ | async); track child) {
      <gf-folder-move-list-item [depth]="this.depth + 1" [folder]="child"
      [sourceFolderIds]="sourceFolderIds"></gf-folder-move-list-item>
    }
  </div>
}
