<div id="editTagsDialog">
  <div mat-dialog-title class="header">
    <div class="flex-container align-middle m-b-2">
      <div class="t-24-400-p text-truncate flex-child-auto">{{translations.art.edit_tags | translate}}</div>
      <mat-icon class="mat-icon-close cursor-pointer" color="black" (click)="close()" fontIcon="close"></mat-icon>
    </div>
    <span class="t-12-500-h">{{ translations.tag.bulk_edit_tags_info | translate }}</span>
  </div>
  <mat-dialog-content>
    @if (!tags.length) {
      <p class="t-16-500-h text-center">{{ translations.tag.selected_files_have_no_tags | translate}}</p>
      <p class="t-14-300-h text-center m-b-2" [innerHTML]="translations.tag.selected_files_have_no_tags_info | translate"></p>
    }
    <gf-manage-art-tag
      [tags]="tags"
      (addTag)="assignTag($event)"
      (createTag)="createAndAssignTag($event)"
      (removeTag)="removeTag($event)">
    </gf-manage-art-tag>
  </mat-dialog-content>

  <mat-dialog-actions class="align-right">
    <button id="cancelBtn"
      mat-dialog-close
      mat-raised-button
      color="secondary"
      class="large">
      {{translations.common.close | translate}}
    </button>
  </mat-dialog-actions>
</div>
