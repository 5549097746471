<div id="genericDialog">
  <div class="cell shrink" mat-dialog-title [class.align-center]="!data?.showCloseButton">
    <h2 class="flex-child-shrink">{{data.header | translate}}</h2>
    @if (data?.showCloseButton) {
      <div class="flex-child-grow"></div>
      <mat-icon class="m-b-2 cursor-pointer flex-child-shrink" (click)="confirm(false)" fontIcon="close"></mat-icon>
    }
  </div>
  <mat-dialog-content>
    <div class="secondary"
    [innerHTML]="data.body | translate: data.bodyParams"></div>
    <ng-container *ngTemplateOutlet="data?.templateRef"></ng-container>
  </mat-dialog-content>
  @if (data.cancelText || data.continueText) {
    <mat-dialog-actions class="align-right full-width-buttons-mobile">
      @if (data.cancelText) {
        <button mat-raised-button
          color="secondary"
          class="large"
          type="button"
          [ngClass]="data?.cancelClass"
          (click)="confirm(false)">
          {{data.cancelText | translate}}
        </button>
      }
      @if (data.continueText) {
        <button mat-raised-button
          class="button-continue large"
          type="button"
          [ngClass]="continueBtnClass"
          (click)="confirm(true)">
          {{data.continueText | translate}}
        </button>
      }
    </mat-dialog-actions>
  }
</div>
