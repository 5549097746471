@if (approval$ | async) {
  <div class="flex-container flex-dir-column p-a-4">
    @if (canApprove || approvalItem.status) {
      <div
        class="flex-container align-justify m-b-4"
        #tooltip="matTooltip"
        [matTooltip]="translations.common.viewers_do_not_have | translate"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="canApprove"
        matTooltipHideDelay="100"
        (click)="tooltip.toggle()">
        @if (canApprove || approvalItem.status === ArtApprovalItemStatus.APPROVED) {
          <button
            mat-raised-button
            color="secondary"
            class="full-width thick-border large"
            [class.cursor-not-allowed]="approvalItem.status === ArtApprovalItemStatus.APPROVED"
            [disabled]="!canApprove || (isArchived$ | async) || (actionItem.action ===  ArtApprovalItemStatus.APPROVED && approvalItemStatusInProgress) || (!!collaborator && approval | isApprovalApproved)"
            [class.success]="approvalItem?.status === ArtApprovalItemStatus.APPROVED"
            (click)="showActionConfirmation(ArtApprovalItemStatus.APPROVED)">
            @if (!(actionItem.action ===  ArtApprovalItemStatus.APPROVED && approvalItemStatusInProgress)) {
              <mat-icon class="mr-4" fontIcon="done"></mat-icon>
            } @else {
              <mat-icon class="mr-4">
                <mat-spinner color="primary" diameter="20"></mat-spinner>
              </mat-icon>
            }
            {{((approvalItem.status === ArtApprovalItemStatus.APPROVED) ? translations.common.approved : translations.common.approve) | translate}}
          </button>
        }
        @if (canApprove || approvalItem.status === ArtApprovalItemStatus.REJECTED) {
          <button
            mat-raised-button
            color="secondary"
            class="full-width m-l-1 thick-border large"
            [class.cursor-not-allowed]="approvalItem.status === ArtApprovalItemStatus.REJECTED"
            [disabled]="!canApprove || (isArchived$ | async) || (actionItem.action ===  ArtApprovalItemStatus.REJECTED && approvalItemStatusInProgress) || (!!collaborator && approval | isApprovalApproved)"
            [class.warn]="approvalItem?.status === ArtApprovalItemStatus.REJECTED"
            (click)="showActionConfirmation(ArtApprovalItemStatus.REJECTED)">
            @if (!(actionItem.action ===  ArtApprovalItemStatus.REJECTED && approvalItemStatusInProgress)) {
              <mat-icon class="mr-4" fontIcon="clear"></mat-icon>
            } @else {
              <mat-icon class="mr-4">
                <mat-spinner color="primary" diameter="20"></mat-spinner>
              </mat-icon>
            }
            {{((approvalItem.status === ArtApprovalItemStatus.REJECTED) ? translations.common.rejected : translations.common.reject) | translate}}
          </button>
        }
      </div>
    }
    @if (((approvalItem.artId | art | async)?.stockArtOrgId || (approvalItem.artId | art | async)?.customizedStockArtId) && !(viewOnly$ | async)) {
      <gf-stock-art-downloaded
        [art]="approvalItem.artId | art | async"
        class="p-x-2">
      </gf-stock-art-downloaded>
    }
    <form
      #approvalItemForm
      name="approvalItemForm"
      [formGroup]="formGroup$ | async"
      autocomplete="off">
      <div>
        <mat-form-field
          class="t-20-500-p m-b-1 width-100 approval-detail-input approval-item-title-detail"
          appearance="fill">
          <textarea
            id="name"
            class="approval-input art-approval-name"
            name="name"
            matInput
            maxlength="1024"
            [cdkAutosizeMinRows]="1"
            cdkTextareaAutosize
            #nameAutosize="cdkTextareaAutosize"
            formControlName="name"
            [readonly]="viewOnly$ | async"
            [placeholder]="translations.art.add_art_name | translate"
            required>
          </textarea>
        </mat-form-field>
        @if (!(viewOnly$ | async) || artApprovalItemFormDetail.get('description').value) {
          <mat-form-field
            class="t-14-400-h width-100 approval-detail-input approval-item-desc-detail textarea"
            appearance="fill">
            <textarea
              id="description"
              matInput
              name="description"
              formControlName="description"
              class="approval-input"
              maxlength="1024"
              [cdkAutosizeMinRows]="1"
              cdkTextareaAutosize
              #descriptionAutosize="cdkTextareaAutosize"
              [readonly]="viewOnly$ | async"
              [placeholder]="translations.common.add_a_description | translate">
            </textarea>
          </mat-form-field>
        } @else {
          <p class="no-description-added">
            {{ translations.common.no_description_added | translate }}
          </p>
        }
      </div>
    </form>
  </div>
}
<mat-divider></mat-divider>
