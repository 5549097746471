<div id="addArtToApprovalDialog">
  <div mat-dialog-title>
    <div class="flex-child-auto">{{translations.art.select_art_approval | translate}}</div>
    <mat-icon class="mat-icon-close cursor-pointer" (click)="close()" fontIcon="close"></mat-icon>
  </div>
  <mat-dialog-content>
    <mat-form-field appearance="fill" class="rounded-fill width-100">
      <mat-icon matPrefix class="hint" fontIcon="search"></mat-icon>
      <input id="searchInput"
        name="searchInput"
        [formControl]="searchControl"
        [placeholder]="translations.art.search_art_approvals | translate"
        matInput>
    </mat-form-field>
    @if (showCreateNewArtApproval) {
      <div id="createNewArtApproval" class="flex-container align-middle create-art-approval m-t-4 pl-4">
        <div id="createArtApprovalIcon" class="icon flex-container align-center align-middle cursor-pointer" (click)="createNewArtApproval()">
          <mat-icon fontIcon="add"></mat-icon>
        </div>
        <div id="createNewArtApprovalTxt" class="create-art-text t-16-500-p cursor-pointer" (click)="createNewArtApproval()">{{translations.common.create_new_art_approval | translate}}</div>
      </div>
    }
    @if (!loading) {
      <div>
        @if (approvals?.length) {
          <p id="recentlyModifiedTxt" class="t-16-500-p m-b-2 m-t-3">{{translations.common.recently_modified | translate}}</p>
          <div class="art-approvals-section"
            [class.create-art-approval-section]="showCreateNewArtApproval">
            @for (approval of approvals; track approval) {
              <div
                id="selectArtApproval"
                class="flex-container align-middle art-item cursor-pointer pa-4"
                [class.selected]="selectedApproval?.artApprovalId === approval.artApprovalId"
                (click)="selectApproval(approval)">
                <div id="approvalName" [ngClass]="approval | getApprovalDisplayName | iconBackground"
                  class="icon flex-container align-center align-middle">
                  {{approval | getApprovalDisplayName | shortName}}
                </div>
                <div class="approval-name flex-container align-cente ml-12 m-b-0 t-16-500-p">
                  <div class="flex-container">
                    <p id="approvalDisplayName" class="text-truncate m-b-0">{{approval | getApprovalDisplayName}}</p>
                    @if (approval.isApproved) {
                      <mat-icon id="verifiedIconMobile" class="approved-icon show-for-tiny-only m-l-2" fontIcon="verified"></mat-icon>
                    }
                  </div>
                  <span id="modifiedDate" class="m-l-1 t-14-400-h text-truncate">{{approval.modifiedDate | fromNowDate}}</span>
                  @if (approval.isApproved) {
                    <mat-icon id="verifiedIcon" class="approved-icon hide-for-tiny-only m-l-2" fontIcon="verified"></mat-icon>
                  }
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="no-approvals-section flex-container align-center align-middle">
            <mat-icon class="mat-30" fontIcon="image_search"></mat-icon>
            <p class="t-16-500-h m-b-0 m-l-1">{{translations.art.no_art_approvals_found | translate}}</p>
          </div>
        }
      </div>
    } @else {
      <div class="flex-container grid-y align-center-middle m-b-1 m-t-3">
        <mat-spinner id="loader" color="primary" diameter="25"></mat-spinner>
      </div>
    }

  </mat-dialog-content>

  <mat-dialog-actions class="align-right border">
    <button id="cancelBtn"
      color="secondary"
      class="large"
      mat-dialog-close
      mat-raised-button>
      {{translations.common.cancel | translate}}
    </button>
    <button id="addFileBtn"
      mat-raised-button
      color="primary"
      class="large"
      [disabled]="!selectedApproval"
      (click)="submit()">
      {{translations.art.add_files | translate}}
    </button>
  </mat-dialog-actions>
</div>
