@if (showInfiniteScrollLoader) {
  <div class="flex-container align-middle results-loading">
    <mat-spinner [diameter]="diameter" [ngClass]="panelClass" [strokeWidth]="2.8" color="primary"></mat-spinner>
    <div class="t-15-600-s">{{ translations.common.loading_results | translate}}</div>
  </div>
} @else {
  <div class="loader-container" [ngClass]="panelClass" [class.overlay]="showOverlay && !showInfiniteScrollLoader">
    <mat-spinner [diameter]="diameter" [strokeWidth]="2.8" color="primary"></mat-spinner>
  </div>
}

