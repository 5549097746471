<div id="tagManagerDialog">
  <div class="position-relative">
    <div mat-dialog-title>
      <div class="flex-container align-middle">
        <h2 class="t-20-500-p m-b-0 text-truncate flex-child-auto">{{translations.tag.tag_manager | translate}}</h2>
        <mat-icon class="mat-icon-close cursor-pointer" color="black" (click)="close()" fontIcon="close"></mat-icon>
      </div>
      <span class="t-12-500-h">{{translations.tag.manage_active_tags_below | translate}}</span>
    </div>
    <div class="flex-container width-100 p-t-1 p-x-3">
      <p
      class="t-12-500-h flex-child-grow">{{ tags.length + ' ' + (tags.length| i18nPlural : tagsCountMessageMap | translate) }}</p>
      <p class="t-12-600-h align-right cursor-pointer text-orange"
      (click)="showNewTagInput()">{{translations.tag.add_tag_with_sign | translate}}</p>
    </div>
  </div>
  <mat-dialog-content>
    @if (showAddNewTag) {
      <div #newTagInputSection class="flex-container border-bottom-grey p-y-1 mobile-decoration-form">
        <form autocomplete="off" [formGroup]="newTagFormGroup" class="flex-child-grow">
          <mat-form-field appearance="fill" class="width-100" hideRequiredMarker
            [class.error-none]="newTagFormGroup.valid || ((newTagFormGroup.untouched && !newTagFormGroup.dirty) && newTagFormGroup.invalid)">
            <mat-label color="primary">{{translations.tag.tag_name | translate}}</mat-label>
            <input matInput #inputNewTagElement id="newTagInput" formControlName="tag" maxlength="64" trim (keyup.enter)="addNewTag()">
            @if (newTagFormGroup.controls['tag'].hasError('alreadyExists')) {
              <mat-error
              >{{translations.tag.tag_already_exists | translate}}</mat-error>
            }
            @if (newTagFormGroup.controls['tag'].hasError('required')) {
              <mat-error
              >{{translations.tag.tag_name_required | translate}}</mat-error>
            }
          </mat-form-field>
        </form>
        <div class="flex-container m-l-2 align-right align-center-middle mobile-decoration-form-actions">
          <button id="creationCancelBtn"
            mat-raised-button
            class="medium"
            color="secondary"
            (click)="resetNewTagField()">
            {{translations.common.cancel | translate}}
          </button>
          <button id="createTagBtn"
            mat-raised-button
            class="medium m-l-1"
            color="primary"
            (click)="addNewTag()">
            {{translations.common.create | translate}}
          </button>
        </div>
      </div>
    }
    @if ((tags?.length > 0 || showAddNewTag)) {
      @for (tag of tags; track tag; let i = $index) {
        @if (!tag.isEdit) {
          <div class="flex-container p-y-1 border-bottom-grey">
            <p class="t-14-600-p text-truncate m-b-0">{{tag.displayName + ' (' + tag.count + ')'}}</p>
            <div class="flex-child-grow"></div>
            <div class="flex-container align-middle">
              <p class="t-12-400-s cursor-pointer m-b-0"
              (click)="editTag(i)"> {{translations.common.edit | translate}}</p>
              @if (!(userQuery.isSupport$ | async)) {
                <p class="t-12-400-s cursor-pointer m-l-2 m-b-0"
                (click)="deleteTag(i)">{{translations.common.delete | translate}}</p>
              }
            </div>
          </div>
        } @else {
          <div class="flex-container p-y-1 border-bottom-grey mobile-decoration-form">
            <form autocomplete="off" class=" flex-child-grow">
              <mat-form-field appearance="fill" class="width-100" [class.error-none]="!!tag.displayName" hideRequiredMarker>
                <mat-label>{{translations.tag.tag_name | translate}}</mat-label>
                <input matInput #tagNameModel="ngModel"
                  [name]="tagNameModel"
                  [ngModel]="tag.displayName"
                  (ngModelChange)="tag.displayName = $event"
                  maxlength="64"
                  required autofocus trim
                  (keyup.enter)="saveExistingTag(i)">
                @if (tagNameModel.hasError('required')) {
                  <mat-error>{{translations.tag.tag_name_required | translate}}</mat-error>
                }
              </mat-form-field>
            </form>
            <div class="flex-container m-l-2 align-right align-center-middle mobile-decoration-form-actions">
              <button id="editingCancelBtn"
                mat-raised-button
                type="reset"
                class="medium"
                color="secondary"
                (click)="cancelExistingTagEdit(i)">
                {{translations.common.cancel | translate}}
              </button>
              <button id="saveTagBtn"
                mat-raised-button
                class="m-l-2 medium"
                color="primary"
                (click)="saveExistingTag(i)">
                {{translations.common.save | translate}}
              </button>
            </div>
          </div>
        }
      }
    } @else {
      <div class="align-middle position-relative m-y-3">
        <div class="no-tags-section">
          <p class="t-16-500-h m-b-0">{{ translations.tag.you_currently_have_no_tags | translate }}</p>
          <p class="t-14-400-h m-t-3 text-center" [innerHTML]="translations.tag.add_tag_instruction | translate"></p>
        </div>
      </div>
    }

    @if (loading) {
      <loading-indicator [showOverlay]="true"></loading-indicator>
    }

  </mat-dialog-content>
  <mat-dialog-actions class="align-right">
    <button id="closeModalBtn"
      mat-raised-button
      color="secondary"
      class="large"
      mat-dialog-close>
      {{translations.common.close | translate}}
    </button>
  </mat-dialog-actions>
</div>
