<div id="approvalItemConfirmationDialog">
  @if (data.action === ArtApprovalItemStatus.APPROVED) {
    <div
      class="position-relative">
      <h1 mat-dialog-title>
        {{ translations.approval.approve_item | translate }}
      </h1>
    </div>
  } @else {
    <div class="flex-container flex-dir-column align-center-middle">
      <mat-icon class="m-t-4 mat-40 material-icons-outlined" color="warn" fontIcon="error_outline"></mat-icon>
      <h1 mat-dialog-title>
        {{ translations.approval.reject_item | translate }}
      </h1>
    </div>
    <mat-divider class="width-100"></mat-divider>
  }
  <mat-dialog-content>
    <p class="t-16-400-p warning-cannot-undone">
      {{
      (data.action === ArtApprovalItemStatus.APPROVED
      ? translations.approval.approve_optional_msg
      : translations.approval.action_optional_msg
      ) | translate
      }}
    </p>

    <mat-form-field
      appearance="outline"
      class="t-14-400-s width-100 square-fill">
      <textarea
        id="actionCommmentTextarea"
        class="approval-action-note"
        matInput
        [ngModel]="data.comment"
        (ngModelChange)="updateComment($event)"
        placeholder="{{ translations.approval.add_comment | translate }}"
        maxlength="1024">
      </textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="align-right">
    <button id="cancelBtn"
      mat-raised-button
      mat-dialog-close
      color="secondary"
      class="large">
      {{ this.translations.common.cancel | translate }}
    </button>

    @if (data.action === ArtApprovalItemStatus.REJECTED) {
      <button
        id="submitBtn"
        mat-raised-button
        color="warn"
        class="large"
        (click)="delete()">
        <ng-container>
          <mat-icon color="white" class="mr-4" fontIcon="clear"></mat-icon>
          {{ translations.common.reject | translate }}
        </ng-container>
      </button>
    } @else {
      <button id="submitBtn"
        mat-raised-button
        color="primary"
        class="large"
        (click)="delete()">
        <ng-container>
          <mat-icon color="white" class="mr-4" fontIcon="done"></mat-icon>
          {{ translations.common.approve | translate }}
        </ng-container>
      </button>
    }
  </mat-dialog-actions>
</div>
