@if (!canShowClipArtMobileViewFilter) {
  <button id="categoryTrigger"
    mat-flat-button
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    color="secondary"
    class="medium show-for-medium"
    type="button"
    (click)="toggle()">
    <ng-container>
      <span class="t-14-500-p">{{translations.stock.category | translate}}</span>
      <mat-icon class="m-l-1 hint" fontIcon="expand_more"></mat-icon>
    </ng-container>
  </button>
  <ng-template
    cdkConnectedOverlay
    #overlay="cdkConnectedOverlay"
    [cdkConnectedOverlayHasBackdrop]="false"
    (overlayOutsideClick)="close()"
    (detach)="detach()"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    [cdkConnectedOverlayOpen]="isOpen$ | async">
    <div class="category-dropdown grid-x mat-elevation-z4">
      <ng-container *ngTemplateOutlet="topLevelCategory"></ng-container>
      <ng-container *ngTemplateOutlet="secondaryLevelCategory"></ng-container>
    </div>
  </ng-template>
}

<ng-container>
  <!-- Disabling animation for stepper in ios cause of: https://github.com/angular/components/issues/28190 -->
  <div class="category-filter-mobile" [class.hide-for-medium]="!canShowClipArtMobileViewFilter"
    [@.disabled]="isIOS && (windowService.smallDown$ | async)">
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <ng-container *ngTemplateOutlet="topLevelCategory"></ng-container>
      </mat-step>
      <mat-step>
        <ng-container *ngTemplateOutlet="secondaryLevelCategory"></ng-container>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</ng-container>

<ng-template #topLevelCategory>
  <div  class="top-level cell small-6 grid-y">
    <div class="category-search cell shrink">
      <mat-form-field appearance="fill"
        [class.small-rounded-fill]="(windowService.mediumUp$ | async) && !canShowClipArtMobileViewFilter"
        [class.filter-form-field]="(windowService.smallDown$ | async) || canShowClipArtMobileViewFilter"
        class="width-100 category-search-input"
        color="secondary">
        <mat-icon [ngClass]="{'search-icon-dark': (windowService.smallDown$ | async) || canShowClipArtMobileViewFilter}" matPrefix fontIcon="search"></mat-icon>
        <input id="searchInput"
          name="searchInput"
          [formControl]="searchControl"
          [placeholder]="translations.common.search | translate"
          matInput>
      </mat-form-field>
    </div>
    <div class="categories-container cell auto">
      <gf-shady-scroller>
        @if (searching$ | async) {
          <a mat-button color="accent" class="medium m-a-1" (click)="searchControl.setValue('')">
            {{((selectedTopLevelCategoryId$ | async) ? translations.stock.back_to_all_categories : translations.stock.show_all_categories) | translate}}
          </a>
        }
        @for (category of topLevelCategories$ | async; track category) {
          <a id="categoryItem"
            class="stock-art-category-item"
            [class.active]="category.folderId === (selectedTopLevelCategoryId$ | async)"
            (click)="selectedTopLevelCategoryId$.next(category.folderId); stepper?.next()">
            <span class="stock-art-category-name">{{category.name}}</span>
            @if (categoriesWithSelection.has(category.folderId)) {
              <mat-icon color="accent" class="selection-icon">check</mat-icon>
            }
            @if (!categoriesWithSelection.has(category.folderId)) {
              <mat-icon color="hint" class="arrow-icon" fontIcon="keyboard_arrow_right"></mat-icon>
            }
          </a>
        }
        @if ((searching$ | async) && !(topLevelCategories$ | async)?.length) {
          <div class="no-results">
            {{ translations.stock.no_results | translate}}
          </div>
        }
      </gf-shady-scroller>
    </div>
  </div>
</ng-template>

<ng-template #secondaryLevelCategory>
  <div class="second-level cell small-6 grid-y" [class.second-level-border]="!canShowClipArtMobileViewFilter">
    @if (selectedTopLevelCategory$ | async; as selectedTopLevelCategory) {
      @if (secondLevelCategories$ | async; as secondLevelCategories) {
        @if (!(searching$ | async)) {
          <div
            class="all-categories-wrapper cell shrink"
            [class.all-categories-wrapper-padding]="canShowClipArtMobileViewFilter">
            <mat-icon id="arrowLeftIcon"
              class="arrow-icon m-r-1 cursor-pointer"
              [class.hide-for-medium]="!canShowClipArtMobileViewFilter"
              (click)="stepper?.previous()">keyboard_arrow_left
            </mat-icon>
            <mat-checkbox (change)="updateSelectedCategories(selectedTopLevelCategory, secondLevelCategories, $event.checked)"
              [indeterminate]="indeterminate$ | async"
              [checked]="selectedCategoryIds.includes(selectedTopLevelCategory.folderId)">
              {{translations.stock.all_x | translate: { x: selectedTopLevelCategory.name } }}
            </mat-checkbox>
          </div>
        }
        @if (searching$ | async) {
          <div class="flex-container align-middle">
            <mat-icon id="arrowLeftIcon"
              class="m-l-3 arrow-icon m-r-1"
              [class.hide-for-medium]="!canShowClipArtMobileViewFilter"
              (click)="stepper?.previous()">keyboard_arrow_left
            </mat-icon>
            <div class="search-results-header" >
              {{ translations.stock.results_in_x | translate : { x: selectedTopLevelCategory.name } }}
            </div>
          </div>
        }
        <div class="categories-container cell auto">
          <gf-shady-scroller>
            <div class="p-y-1">
              @for (category of secondLevelCategories; track category) {
                <mat-checkbox class="stock-art-category-item" [class.stock-art-category-item-padding]="canShowClipArtMobileViewFilter"
                  (change)="updateSelectedSubCategories(selectedTopLevelCategory, category, $event.checked)"
                  [checked]="selectedSubCategoryIds.includes(category.folderId) || selectedCategoryIds.includes(category.parentId)">
                  {{category.name}}
                </mat-checkbox>
              }
              @if (searching$ | async) {
                <a mat-button
                  color="accent"
                  class="medium m-a-1"
                  (click)="searchControl.setValue('')">
                  {{translations.stock.view_all_sub_categories | translate}}
                </a>
              }
            </div>
          </gf-shady-scroller>
        </div>
      }
    } @else {
      <gf-empty-state icon="image_search"
      [message]="translations.stock.select_a_category | translate"></gf-empty-state>
    }
  </div>
</ng-template>
