<div class="color-preview" [matMenuTriggerFor]="colorPickerMenu" #colorPickerMenuTrigger="matMenuTrigger"
  [style.border-color]="color | borderColor" [style.background-color]="!!color ? color : '#ffffff'"
(menuClosed)="colorPickerClosed()" [class.disabled]="disabled"></div>

<mat-menu #colorPickerMenu="matMenu" class="color-picker" [overlapTrigger]="false">
  <div class="color-picker-wrap m-a-1 p-a-1" (click)="$event.stopPropagation()">
    <color-picker [(color)]="color" [allowClear]="false"
      [isColorPalette]="true"
      [showCMYKPicker]="showCMYKPicker"
      (allColorFormat)="allColorFormat.emit($event)"
      #colorPicker>
  </color-picker>
  <div class="p-a-1">
    @if (isColorPalette) {
      <button
        id="colorPickerToggleTigger"
        color="primary"
        class="m-b-2 medium full-width"
        mat-raised-button
        (click)="toggleColorPickerType()">
        {{ (translations.organization.tab.color_palettes_management[showCMYKPicker ? 'switch_to_rgb' : 'switch_to_cmyk']) | translate}}
      </button>
    }
    <button id="closeBtn"
      mat-raised-button
      color="secondary"
      class="full-width m-b-1"
      (click)="colorPickerMenuTrigger.closeMenu()">
      {{ translations.common.close | translate}}
    </button>
  </div>
</div>
</mat-menu>
