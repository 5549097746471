<div id="manageDecorationsMethodDialog">
  <div mat-dialog-title>
    <div class="flex-child-auto">{{translations.art.decoration_methods | translate}}</div>
    <mat-icon class="mat-icon-close cursor-pointer" (click)="close()" fontIcon="close"></mat-icon>
  </div>
  <mat-dialog-content>
    <div class="align-middle position-relative">

      <div class="flex-container width-100 border-bottom-grey">
        @if (decorationMethods?.length <= 1) {
          <p class="t-12-500-s flex-child-grow">
          {{translations.art.x_decoration_method | translate : { x: decorationMethods?.length } }}</p>
        } @else {
          <p class="t-12-500-h flex-child-grow">{{translations.art.x_decoration_methods | translate : {
            x:
            (decorationMethodsCount$ | async)
          } }}</p>
        }
        <p class="t-12-600-h align-right cursor-pointer text-orange" (click)="showCreateDecorationMethodForm()">
        {{ ((this.windowService.smallDown$ | async ) ? translations.common.add : translations.art.add_decoration_method) | translate}}</p>
      </div>

      @if (addNewDecorationMethod) {
        <div class="flex-container p-y-1 border-bottom-grey mobile-decoration-form">
          <form #newDecorationMethodForm="ngForm" class="flex-child-grow">
            <mat-form-field appearance="fill" class="m-b-0 width-100" hideRequiredMarker>
              <mat-label color="primary">{{translations.art.decoration_method | translate}}</mat-label>
              <input matInput name="newDecorationMethodInput"
                id="newDecorationMethodInput"
                color="primary"
                maxlength="132"
                autocomplete="off"
                #newDecorationMethodInput="ngModel"
                [ngModel]="newDecorationMethod"
                (ngModelChange)="newDecorationMethod = $event"
                required trim
                (keyup.enter)="add()">
              @if (newDecorationMethodInput.hasError('required')) {
                <mat-error>{{translations.art.decoration_method_name_required | translate}}</mat-error>
              }
            </mat-form-field>
          </form>
          <div class="flex-container m-l-2 align-center-middle mobile-decoration-form-actions">
            <button id="cancelBtn"
              mat-raised-button
              color="secondary"
              type="reset"
              class="medium"
              (click)="hideCreateDecorationMethodForm()">
            {{translations.common.cancel | translate}}</button>
            <button id="createBtn"
              mat-raised-button
              class="m-l-2 medium"
              color="primary"
              (click)="add()">
            {{translations.common.create | translate}}</button>
          </div>
        </div>
      }
      <form>
        <div>
          @for (dm of decorationMethods; track dm; let i = $index) {
            <div>
              @if (!dm.deleted) {
                @if (!dm.isEdit) {
                  <div class="flex-container p-y-1 border-bottom-grey">
                    <p class="t-14-600-p text-truncate m-b-0">{{dm?.name}}</p>
                    <div class="flex-child-grow"></div>
                    <div class="flex-container align-middle">
                      <p class="t-12-400-s cursor-pointer m-b-0"
                      (click)="edit(i)"> {{translations.common.edit | translate}}</p>
                      <p class="t-12-400-s cursor-pointer m-l-2 m-b-0"
                      (click)="remove(i)">{{translations.common.delete | translate}}</p>
                    </div>
                  </div>
                } @else {
                  <div class="flex-container p-y-1 border-bottom-grey mobile-decoration-form">
                    <div class="flex-child-grow">
                      <mat-form-field appearance="fill" class="width-100" hideRequiredMarker>
                        <mat-label>{{translations.art.decoration_method | translate}}</mat-label>
                        <input matInput #decorationMethodModel="ngModel"
                          name="decoration-method-{{i}}"
                          [ngModel]="dm?.name"
                          (ngModelChange)="dm.name = $event"
                          maxlength="132"
                          required autofocus trim
                          (keyup.enter)="save(i)">
                        @if (decorationMethodModel.hasError('required')) {
                          <mat-error>{{translations.art.decoration_method_name_required | translate}}</mat-error>
                        }
                      </mat-form-field>
                    </div>
                    <div class="flex-container m-l-2 align-center-middle mobile-decoration-form-actions">
                      <button id="cancelEditBtn"
                        mat-raised-button
                        color="secondary"
                        type="reset"
                        class="medium"
                        (click)="cancelEdit(i)">
                        {{translations.common.cancel | translate}}
                      </button>
                      <button id="saveBtn"
                        mat-raised-button
                        color="primary"
                        class="m-l-2 medium"
                        (click)="save(i)">
                        {{translations.common.save | translate}}
                      </button>
                    </div>
                  </div>
                }
              }
            </div>
          }
        </div>
      </form>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="align-right">
    <button id="cancelButton"
      mat-raised-button
      color="secondary"
      class="large"
      (click)="close()">
      {{translations.common.close | translate}}
    </button>
  </mat-dialog-actions>
</div>
