import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Translations } from '@graphics-flow/shared/assets';
import { ID, StockArt, StockArtDetailSections, StockArtRequest, StockArtType } from '@graphics-flow/types';
import { BillingPlansQuery, MyArtUploaderService, StockArtDetailQuery, StockArtDetailService, StockArtHelper, StockArtListQuery, StockArtListService, StockArtService } from '@graphics-flow/util';
import { VirtualScrollerComponent } from '@genexus/ngx-virtual-scroller';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { WindowService } from 'shared/util';

@UntilDestroy()
@Component({
  selector: 'gf-stock-art-list',
  templateUrl: './stock-art-list.component.html',
  styleUrls: ['./stock-art-list.component.scss']
})
export class StockArtListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('scrollingBlock') stockArtListElementRef: ElementRef;
  @ViewChild(VirtualScrollerComponent) private virtualScroller: VirtualScrollerComponent;
  @Input() type: StockArtType = StockArtType.DesignIdea;
  @Input() filterClass = '';
  @Input() selectable = false;
  @Input() selectMultiple = true;
  isLarge$: Observable<boolean> = this.windowService.largeUp$;
  StockArtType: typeof StockArtType = StockArtType;

  scrolledToBottom$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  infinite$: Observable<StockArt[]> = this.scrolledToBottom$.pipe(
    tap((offset: number) => this.stockArtListService.getBatch(offset)),
    switchMap(() => this.stockArtListQuery.filteredStockArts$)
  );
  stockArtDetailSections: typeof StockArtDetailSections = StockArtDetailSections;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly myArtUploaderService: MyArtUploaderService,
    private readonly router: Router,
    private readonly stockArtDetailService: StockArtDetailService,
    public readonly stockArtListQuery: StockArtListQuery,
    public readonly stockArtListService: StockArtListService,
    public readonly stockArtService: StockArtService,
    public readonly translations: Translations,
    public readonly windowService: WindowService,
    public readonly billingPlansQuery: BillingPlansQuery,
    public readonly stockArtDetailQuery: StockArtDetailQuery
  ) {
    if (this.stockArtListService.stockArtListStoreInfo) {
      this.stockArtListService.updateActiveStoreInfo();
    }
    this.stockArtListService.initialize();
  }

  ngOnInit(): void {
    // Discern whether we're on DesignIdeas, Clip Art, or Fonts ... this can be passed in via route data or
    // via Input() (as select-stock-art does)
    const type: StockArtType = this.route.snapshot.data['type'] || this.type;
    this.type = type;
    if (!this.stockArtListService.stockArtListStoreInfo) {
      this.stockArtListService.setType(type);
    }

    // Subscribe to the activeFilters and update the url query parameters
    this.stockArtListQuery.filtersAsQueryParams$.pipe(
      untilDestroyed(this)
    ).subscribe(
      (queryParams: { orderBy, orderDesc, filterHeaders }) => {
        this.router.navigate([], {
          replaceUrl: true,
          //skipLocationChange
          queryParams: queryParams
        });
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type && !this.stockArtListService.stockArtListStoreInfo) {
      this.stockArtListService.setType(changes.type.currentValue);
    }
  }

  ngAfterViewInit() {
    this.parseQueryParams();
    this.stockArtListService.setStockArtListStoreInfo(false);
    setTimeout(() => {
      this.scrollToPreviousScrollPosition();
    }, 0);
  }

  ngOnDestroy() {
    this.stockArtListService.clearSubscriptions();
  }

  parseQueryParams() {
    const filtersFromParams: StockArtRequest = StockArtHelper.convertQueryParamsToStockArtRequest(this.route.snapshot.queryParams);
    // just updating filters in the store will take care of everything
    this.stockArtListService.setActiveFilters(filtersFromParams);
  }

  openStockArtDetail(stockArtId: ID, activeSection: StockArtDetailSections): void {
    // In case the scroll event didn't happen we are updating the scroll position, when routing between the detail view & list view!
    if (this.stockArtListElementRef.nativeElement.scrollTop !== this.stockArtListService.stockArtListScrollPosition) {
      this.stockArtListService._isScrolledTop.next(this.stockArtListElementRef.nativeElement.scrollTop !== 0);
      this.stockArtListService.setScrollYPosition(this.stockArtListElementRef.nativeElement.scrollTop);
    }
    this.stockArtListService.setStockArtListStoreInfo();
    this.stockArtDetailService.show(stockArtId, activeSection, this.type);
  }

  trackStockArt(index: number, stockArt: StockArt) {
    return stockArt?.stockArtId;
  }

  scrolledToBottom(index: number, offset: number) {
    if (index === offset - 1 && this.stockArtListQuery.getStockArtsPagination().totalResults !== offset) {
      this.scrolledToBottom$.next(offset);
    }
  }

  // since we need to handle usage notification for mobile on scroll.
  // when scrollTop = 0 we will show full info about the usage, otherwise a short-note.
  scrolledTop(scrollEvent): void {
    this.stockArtListService._isScrolledTop.next(scrollEvent.target.scrollTop !== 0);

    // Used to maintain the scroll position, when routing between detail view & list view!
    this.stockArtListService.setScrollYPosition(scrollEvent?.target?.scrollTop);
  }

  onClick(stockArt: StockArt) {
    this.myArtUploaderService.closePanelIfDisplaying();
    if (!this.selectable) {
      this.openStockArtDetail(stockArt.stockArtId, StockArtDetailSections.INFO);
    }
    if(!this.selectMultiple) {
      this.selectArt(stockArt);
    }
  }

  select(stockArt: StockArt, selected: boolean) {
    this.stockArtListService.selectStockArt(stockArt.stockArtId, selected);
  }

  selectArt(stockArt: StockArt) {
    this.stockArtListService.clearSelectedStockArts();
    this.stockArtListService.selectStockArt(stockArt.stockArtId, true);
  }

  scrollToPreviousScrollPosition(): void {
    if (this.stockArtListService.stockArtListScrollPosition) {
      this.virtualScroller?.scrollToPosition(this.stockArtListService.stockArtListScrollPosition);
    }
  }
}
