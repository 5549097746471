<div [ngClass]="{'overall-loader position-relative': isLoading}">
  @if (!isLoading) {
    <div class="grid-x plan-option">
      @for (planOption of plans; track planOption; let i = $index) {
        <div class="plan-container grid-y cell large-4 xlarge-4 medium-12 small-12"
          [class.m-b-2]="windowService.smallDown$">
          <div class="private-branding-icon-wrap m-b-3">
            <img src="/assets/images/logo-symbol.svg" id="gfLogo" class="gf-logo" [alt]="translations.common.graphics_flow_logo | translate"/>
          </div>
          <p id="planOptionName{{i}}" class="t-20-400-p dark-text mb-4">{{(planOption.name | translate)}}</p>
          <p id="planOptionPrice{{i}}" class="t-16-600-p dark-text">{{ (planOption.price | currency: 'USD' : 'symbol' : '1.0-2') }}
            <span class="t-16-500-p">/{{ translations.common.month | translate }}</span>
          </p>
          <ul id="planOptionDetails{{i}}" class="t-13-400-s p-l-2 m-a-0">
            @if (planOption.users) {
              <li class="m-b-1">{{planOption.product.productMetadata.users + ' ' +
              (translations.organization.tab.team_members | translate) }}</li>
            }
            @if (planOption.storage) {
              <li class="m-b-1">{{ translations.plans.of_storage | translate : { storage:
              (planOption.product.storageBytes | filesize : { base: 2, standard: 'jedec' } ) } }}</li>
            }
            @if (planOption?.downloads) {
              <li class="m-b-1">{{ translations.plans.bundle.stock_art_downloads | translate:{
              count: planOption.product.productMetadata?.downloads } }}</li>
            }
            @if (!!planOption.product.productMetadata.private_branding) {
              <li class="m-b-1">{{
              translations.common.private_branding | translate }}</li>
            }
            @if (!!planOption.product.productMetadata.graphics_builder) {
              <li class="m-b-1">{{
              translations.plans.comparison.pro_graphics_collection | translate }}</li>
            }
            @if (!!planOption.product.productMetadata.public_catalog) {
              <li class="m-b-1">{{
              translations.public_stock_art.art_portal | translate }}</li>
            }
            @if (!!planOption.product.productMetadata.customize_stockart) {
              <li class="m-b-1">{{
              translations.custom_stock_art.in_app_stock_art_editing | translate }}</li>
            }
          </ul>
          <div class="flex-child-grow"></div>
          @if (!isAccountReactivation && !(updateSubscriptionPlanService.isReactivationInProgress$ | async)) {
            @if ((planOption.name !== selectedPlan.name) && !(planOption | isDowngradeAllowed | async)) {
              <div
                id="downgradePlansWarning" class="plan-downgrade-warning t-13-400-p">
                <p class="m-b-0">
                  {{translations.plans.unable_to_downgrade_plans | translate}}
                  {{translations.common.please | translate}} <a href='https://www.graphicsflow.com/help/'
                target="_blank">{{translations.plans.contact_us | translate}}</a> {{translations.plans.for_assitance |
                translate}}.
              </p>
            </div>
          }
        }
        @if (!isAccountReactivation) {
          @if (selectedPlan.name === planOption.name) {
            <button id="currentPlanBtn" type="button" mat-raised-button color="secondary" disabled
              class="t-15-600-h m-t-2 text-truncate large">
              {{translations.plans.current_plan | translate}}
            </button>
          } @else {
            @if (updateSubscriptionPlanService.selectedPlanForm.value?.selectedPlan?.productId === planOption.product.productId) {
              <button id="selectedPlanBtn" type="button" mat-raised-button disabled color="secondary"
                class="t-15-600-h m-t-2 text-truncate">
                {{translations.plans.selected_plan | translate}}
              </button>
            } @else {
              <button id="uprgradePlanBtn" type="button" mat-raised-button
                [disabled]="!(planOption | isDowngradeAllowed | async) && !(updateSubscriptionPlanService.isReactivationInProgress$ | async)"
                class="notification-btn mat-48 t-15-600-h m-t-2 text-truncate"
                (click)="newPlanSelection.emit(planOption)">
                {{ existingPlan?.price > planOption?.price
                ? (translations.plans.select_plan | translate)
                : (translations.plans.upgrade_to_plan | translate : { plan: planOption.plan } | titlecase)
                }}
              </button>
            }
          }
        } @else {
          <button id="newSelectPlanBtn" type="button" mat-raised-button
            class="t-15-600-h m-t-2 text-truncate notification-btn" (click)="newPlanSelection.emit(planOption)">
            {{translations.plans.select_plan | translate}}
          </button>
        }
      </div>
    }
  </div>
  @if (!isAccountReactivation) {
    <div
      id="viewDetailPlan" class="align-center grid-x m-t-3 t-14-500-p link-color align-middle show-for-large">
      <div class="flex-container" (click)="expandPlan = !expandPlan">
        <span id="viewDetailPlanDescription" class="cursor-pointer">{{translations.plans.view_detailed_plan_comparison | translate}}</span>
        <mat-icon id="explanPlanIcon" class="cursor-pointer m-l-2" fontIcon="{{expandPlan ? 'expand_more' : 'expand_less'}}"></mat-icon>
      </div>
    </div>
  }
  @if (expandPlan || isAccountReactivation) {
    <div id="planComparisonTable" [ngClass]="{'show-for-large' : !isAccountReactivation}"
      class="plan-comparison">
      <div class="grid-x plan-row-header">
        <div class="flex-1 p-a-2">
          <p class="t-24-400-p dark-text">{{translations.plans.plan_comparison | translate}}</p>
        </div>
        @for (planDescription of planTable.plan; track planDescription; let i = $index) {
          <div
            id="plan{{i}}"
            class="plan-feature-cell grid-x align-middle grid-y"
            [class.active]="existingPlan.name === planDescription.name">
            <p class="current-plan t-13-600-p" [class.active]="existingPlan.name === planDescription.name">
              {{ isAccountReactivation ? (translations.reactivation.most_popular | translate) :
              (translations.plans.current_plan | translate)}}
            </p>
            <p class="t-16-600-p m-b-0 dark-text">
              {{planDescription.name | translate}}
            </p>
            <p class="t-14-400-p dark-text">
              {{translations.plans.bundle.price_per_month | translate : { price: planDescription.price | currency: 'USD':
              'symbol' : '1.0-2' } }}
            </p>
          </div>
        }
      </div>
      <div class="grid-x plan-row">
        <div class="flex-1 p-a-2">
          <p class="t-14-600-p dark-text mb-4">{{translations.plans.comparison.team_members | translate}}</p>
          <p class="t-12-400-p">{{translations.plans.comparison.team_members_description | translate}}</p>
        </div>
        @for (teamMember of planTable.teamMembers; track teamMember; let i = $index; let isLast = $last) {
          <div
            id="teamMembers{{i}}"
            class="plan-feature-cell grid-x align-center-middle"
            [class.active]="existingPlan.name === planTable.plan[i].name"
            [class.border-right]="(!isLast && !allowedBorders.includes(i))">
            <p class="t-14-400-p dark-text">{{teamMember}}</p>
          </div>
        }
      </div>
      <div class="grid-x plan-row">
        <div class="flex-1 p-a-2">
          <p class="t-14-600-p dark-text mb-4">{{translations.plans.comparison.storage | translate}}</p>
          <p class="t-12-400-p">{{translations.plans.comparison.storage_description | translate}}</p>
        </div>
        @for (storage of planTable.storage; track storage; let i = $index; let isLast = $last) {
          <div
            id="storage{{i}}"
            class="plan-feature-cell grid-x align-center-middle"
            [class.active]="existingPlan.name === planTable.plan[i].name"
            [class.border-right]="(!isLast && !allowedBorders.includes(i))">
            <p class="t-14-400-p dark-text">{{storage | filesize : { base: 2, standard: 'jedec' } }}</p>
          </div>
        }
      </div>
      <div class="grid-x plan-row">
        <div class="flex-1 p-a-2">
          <p class="t-14-600-p dark-text mb-4">{{translations.plans.comparison.stock_art_downloads | translate}}</p>
          <p class="t-12-400-p">{{translations.plans.comparison.stock_art_downloads_description | translate}}</p>
        </div>
        @for (stockArt of planTable.stockArtDownloads; track stockArt; let i = $index; let isLast = $last) {
          <div
            id="stockArtDownloads{{i}}"
            class="plan-feature-cell grid-x align-center-middle"
            [class.active]="existingPlan.name === planTable.plan[i].name"
            [class.border-right]="(!isLast && !allowedBorders.includes(i))">
            <p class="t-14-400-p dark-text">{{stockArt}}</p>
          </div>
        }
      </div>
      <div class="grid-x plan-row">
        <div class="flex-1 p-a-2">
          <p class="t-14-600-p dark-text mb-4">{{translations.plans.comparison.private_branding | translate}}</p>
          <p class="t-12-400-p">{{translations.plans.comparison.private_branding_description | translate}}</p>
        </div>
        @for (privateBranding of planTable.privateBranding; track privateBranding; let i = $index; let isLast = $last) {
          <div
            id="privateBranding{{i}}"
            class="plan-feature-cell grid-x align-center-middle"
            [class.active]="existingPlan.name === planTable.plan[i].name"
            [class.border-right]="(!isLast && !allowedBorders.includes(i))">
            @if (privateBranding) {
              <mat-icon color="primary">check</mat-icon>
            }
            @if (!privateBranding) {
              <p class="t-14-400-p dark-text">-</p>
            }
          </div>
        }
      </div>
      <div class="grid-x plan-row">
        <div class="flex-1 p-a-2">
          <p class="t-14-600-p dark-text mb-4">{{translations.plans.comparison.pro_graphics_collection | translate}}</p>
          <p class="t-12-400-p">{{translations.plans.comparison.pro_graphics_collection_description | translate}}</p>
        </div>
        @for (gfCollection of planTable.proGraphicsCollection; track gfCollection; let i = $index; let isLast = $last) {
          <div
            id="proGraphicsCollection{{i}}"
            class="plan-feature-cell grid-x align-center-middle active"
            [class.active]="existingPlan.name === planTable.plan[i].name"
            [class.border-right]="(!isLast && !allowedBorders.includes(i))">
            @if (gfCollection) {
              <mat-icon color="primary">check</mat-icon>
            }
            @if (!gfCollection) {
              <p class="t-14-400-p dark-text">-</p>
            }
          </div>
        }
      </div>
      <div class="grid-x plan-row">
        <div class="flex-1 p-a-2">
          <p class="t-14-600-p dark-text mb-4">{{translations.plans.comparison.public_catalog_collection | translate}}</p>
          <p class="t-12-400-p">{{translations.plans.comparison.public_catalog_collection_description | translate}}</p>
        </div>
        @for (gfPublicCatalog of planTable.publicCatalog; track gfPublicCatalog; let i = $index; let isLast = $last) {
          <div
            id="publicCatalog{{i}}"
            class="plan-feature-cell grid-x align-center-middle active"
            [class.active]="existingPlan.name === planTable.plan[i].name"
            [class.border-right]="(!isLast && !allowedBorders.includes(i))">
            @if (gfPublicCatalog) {
              <mat-icon color="primary">check</mat-icon>
            }
            @if (!gfPublicCatalog) {
              <p class="t-14-400-p dark-text">-</p>
            }
          </div>
        }
      </div>
      <div class="grid-x plan-row">
        <div class="flex-1 p-a-2">
          <p class="t-14-600-p dark-text mb-4">{{translations.plans.comparison.customize_stock_art | translate}}</p>
          <p class="t-12-400-p">{{translations.plans.comparison.customize_stock_art_description | translate}}</p>
        </div>
        @for (gfCustomizeStockArt of planTable.customizeStockArt; track gfCustomizeStockArt; let i = $index; let isLast = $last) {
          <div
            id="customizeStockArt{{i}}"
            class="plan-feature-cell grid-x align-center-middle active"
            [class.active]="existingPlan.name === planTable.plan[i].name"
            [class.border-right]="(!isLast && !allowedBorders.includes(i))">
            @if (gfCustomizeStockArt) {
              <mat-icon color="primary">check</mat-icon>
            }
            @if (!gfCustomizeStockArt) {
              <p class="t-14-400-p dark-text">-</p>
            }
          </div>
        }
      </div>
    </div>
  }
} @else {
  <loading-indicator></loading-indicator>
}

</div>
