import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TransformationRange } from '@graphics-flow/types';

@Directive({
  selector: '[onlyNumber]'
})
export class OnlyNumberDirective {

  constructor(private elementRef: ElementRef) { }

  @Input() onlyNumber: boolean;
  @Input() allowDecimals = true;
  @Input() allowNegative = false;
  @Input() removeLeadingZeros = false;
  @Input() roundOffOnFocusOut = false;
  @Input() decimals = 2;
  @Input() allowZero = true;
  @Input() roundToMaxOrMinValue = false;
  @Input() range: TransformationRange;

  @Input() ngModel: string;
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() updateNumber: EventEmitter<number> = new EventEmitter<number>();

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (this.onlyNumber) {
      // allow Delete, Backspace, Tab, Escape, Enter
      const allowedCodes: number[] = [46, 8, 9, 27, 13];
      if (this.allowDecimals) {
        allowedCodes.push(110); // decimal
        allowedCodes.push(190); // period
      } else {
        if (this.removeLeadingZeros) {
          (<HTMLInputElement>event.target).value = (<HTMLInputElement>event.target).value.replace(/^0+/, '');
        }
      }
      if (this.allowNegative
          && (e.keyCode === 189 || e.keyCode === 109)
          && ((<HTMLInputElement>e.target).type === 'number' || (<any>e.target).selectionStart === 0)) {
        // Allow the -
        allowedCodes.push(109);
        allowedCodes.push(189);
      }
      if (allowedCodes.indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A and Cmd+A (Mac)
        (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
        // Allow: Ctrl+C and Cmd+C (Mac)
        (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
        // Allow: Ctrl+X and Cmd+X (Mac)
        (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
        // Allow: Ctrl+V and Cmd+V (Mac) (allowing paste can allow text if text is on the clipboard, do we want to give up paste?  I
        // say no, but I really like paste especially for credit card numbers)
        (e.keyCode === 86 && (e.ctrlKey === true || e.metaKey === true)) ||
        // Allow: home, end, left, right, up, down
        (e.keyCode >= 35 && e.keyCode <= 40)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }

  @HostListener('focusout', ['$event']) roundingOffOnFocusOut(): void {
    if (this.roundOffOnFocusOut) {
      const value: string = Number(this.ngModel).toFixed(2);
      this.ngModelChange.emit(value);
    }
    if (!this.allowZero && !this.ngModel) {
      this.ngModelChange.emit('');
    }
    if (this.roundToMaxOrMinValue && (Number(this.elementRef.nativeElement.value) > this.range.max)) {
      this.updateNumber.emit(this.range.max);
    }
    if (this.roundToMaxOrMinValue && (Number(this.elementRef.nativeElement.value) <= this.range.min)) {
      this.updateNumber.emit(this.range.min);
    }
  }
}
